<main class="arrange">
    <app-subheader [title]="title"></app-subheader>

    <section class="sub-wrapper">

      <div class="data-hint arr-hint">
        <div class="icon">
          <i class="icon icon-flag"></i>
        </div>
        <small class="text">
            Arrastre y ordene las secciones, como más le
            convenga a su recorrido.
        </small>
      </div>

      <section class="columnas-tabs opcionales ordering">
          <div class="secciones box-list" cdkDropList (cdkDropListDropped)="drop($event)">
            <ng-container *ngFor="let section of generatedArray; let i = index">
              <div cdkDrag class="ind-order">
                  <span class="order-id"> {{i + 1 }} </span>
                  <span class="ind-order-info">{{ section.name }}</span>
                  <i class="icon icon-close" (click)="remove(section)"></i>
              </div>
            </ng-container>
          </div>
        </section>

      <div class="action">
        <button class="button-app" (click)="back()">Volver</button>
        <button class="button-app" (click)="sendOrderedArray()">Empezar</button>
      </div>

    </section>

</main>
