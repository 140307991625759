<div class="main-scores" [ngClass]="{init:started}">

  <div class="headline row">
      <div class="prom column">
          <h1>{{
            scoreResult && scoreResult.calificacionGlobal
            ? getPercentajeToFixedTwo(scoreResult.calificacionGlobal.true_porcentaje)
            : 'No se pudo obtener la evaluación del hotel, favor de comunicarse con soporte.'
          }}</h1>
          <span class="prom-title">Promedio</span>
      </div>
      <sup><b>%</b></sup>
  </div>

  <div class="datos column" *ngIf="scoreResult && scoreResult.calificacionGlobal">

    <div class="line row headTable">
      <div class="column category">
        <span>
          <h3>Evals</h3>
        </span>
      </div>

      <span>
        Puntos
      </span>
      <span>
        Porcentaje
      </span>
    </div>

    <div class="line row realData" *ngFor="let scoreRow of scoreRows">
      <div class="column category">
        <i [attr.class]="'icon '+scoreRow.icon"></i>
        <span>{{scoreRow.name}}</span>
      </div>

      <span>
        {{scoreRow.eval1}}
      </span>
      <span>
        {{scoreRow.eval2}}%
      </span>
    </div>

  </div>

  <span #master> </span>

</div>
