<div class="loading">
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="353.982px" height="360.571px" viewBox="0 0 353.982 360.571" enable-background="new 0 0 353.982 360.571"
	 xml:space="preserve">
<path stroke="#FFFFFF" stroke-miterlimit="10" d="M308.728,360.571c0.06-0.03,0.11-0.06,0.17-0.09v0.02
	C308.838,360.521,308.787,360.551,308.728,360.571z"/>

<path class="larger" fill="#009FD4" d="M331.772,172.432c0,62.905-37.657,117.02-91.673,141.025c-0.06,0.02-0.109,0.048-0.169,0.069
	c0.06-0.03,0.109-0.049,0.169-0.089c44.55-24.939,74.659-72.591,74.659-127.282c0-13.046-1.721-25.695-4.942-37.728
	C298.511,85.62,243.551,37.969,177.494,37.969c-12.987,0-25.577,1.84-37.46,5.281C84.01,59.458,42.952,111.136,42.952,172.392
	c0,27.335,8.117,52.762,22.128,73.982c1.452,2.198,2.318,4.346,4.318,6.444v0.01c2,2.585,4.154,5.102,6.262,7.538
	c1.481,1.711,3.305,3.391,4.847,5.032c0.328,0.338,0.574,0.686,0.902,1.033c0.317,0.338,0.5,0.677,0.838,1.015
	c24.323,24.323,57.629,39.368,94.75,39.368c3.203,0,6.401-0.109,9.401-0.338v0.11c0,0,0.107-0.01,0.146-0.01
	c0.259-0.021,0.547-0.07,0.814-0.07c1.113,0,2.187,0.179,3.191,0.507c0.14,0.05,0.284,0.099,0.422,0.149
	c0.379,0.149,0.738,0.319,1.097,0.507c0.846,0.457,1.623,1.023,2.309,1.7c0.228,0.228,0.438,0.458,0.646,0.706
	c0,0,0.021,0.01,0.021,0.02c0.298,0.358,0.577,0.746,0.815,1.144c0.428,0.686,0.766,1.422,1.005,2.198
	c0.089,0.308,0.179,0.626,0.249,0.955c0.129,0.647,0.199,1.313,0.199,1.999c0,4.972-3.649,9.089-8.423,9.814
	c-0.02,0.011-0.039,0.011-0.059,0.011c-0.319,0.029-0.646,0.049-0.974,0.069c-0.13,0.011-0.259,0.02-0.388,0.03
	c-0.07,0-0.14,0.01-0.22,0.01c-0.655,0.04-1.312,0.08-1.978,0.12c-2.894,0.159-5.828,0.238-8.701,0.238
	c-83.101,0-151.187-65.719-154.17-148.024c0-0.01,0-0.02,0-0.02c0-2.059,0.397-4.127,0.397-6.205
	c0-85.18,69.558-154.241,154.747-154.241C262.731,18.191,331.772,87.251,331.772,172.432z"/>
<path class="smaller" fill="#003555" d="M243.798,273.461c-1.7,0.97-3.601,1.83-5.28,2.79c0,0.01-0.01,0.01-0.01,0.01
	c-46.97,23.46-103.101,12.07-136.76-26.39c32.68,31.6,83.52,39.3,124.94,15.56c20.39-11.7,35.38-29.19,44.04-49.23l0.01,0.01
	c1.01-2.31,1.92-4.65,2.75-7.03c0.83-2.37,1.58-4.77,2.23-7.2c7.02-25.87,4.12-54.53-10.271-79.62
	c-2.68-4.68-8.899-13.22-8.899-13.22c-0.15-0.2-0.29-0.4-0.42-0.61c-0.11-0.15-0.19-0.31-0.28-0.47c-0.09-0.18-0.17-0.35-0.25-0.53
	c-0.49-1.1-0.76-2.31-0.76-3.59c0-4.86,3.899-8.8,8.699-8.8c3.03,0,5.401,1.058,7.714,3.511c2.83,3.002,10.267,11.899,14.746,19.719
	C317.207,172.811,298.237,242.261,243.798,273.461z"/>
</svg>
</div>