import { Component, OnInit } from '@angular/core';
import { HoteldataService } from '../../services/hoteldata.service'
import { Router } from '@angular/router';
import { Categoria, Evaluation, Hotels, Sections } from '../planning';
import { EvalService } from 'src/app/rest/eval.service';
import { mergeMap, last, pluck, map } from 'rxjs/operators';

@Component({
  selector: 'app-sort',
  templateUrl: './sort.component.html'
})
export class SortComponent implements OnInit {
  categorias: Categoria[] = [];

  constructor(
    private _hoteldataService: HoteldataService,
    private _router: Router,
    private _evalService: EvalService
  ) {



  }


    // For select info
    currentPlantilla:string;

  secciones: Sections[] = [];

  //array Hoteles
  hoteles:Array<Hotels> = this._hoteldataService.Hoteles;

  //array claves secciones
  ActiveSections: Array<string> = this._hoteldataService.currentConfig.data.secciones;


  back(){
    this.autosave();
    this._router.navigate(['/planning']);
  }

  async send() {
    if (!this._hoteldataService.currentConfig.data.test.id) return;
    this._hoteldataService.restore();

    let _limitScore: number = await this._hoteldataService.makeLimitScore(this.ActiveSections);
    let _finalScore: string = `${this._hoteldataService.currentConfig.data.playing_score} - ${_limitScore}`;

    this._hoteldataService.currentConfig.limit_score = _limitScore;

    this.autosave();

    let _syncData = {
      "puntuacion_final": _finalScore,
      "secciones": JSON.stringify(this.ActiveSections),
      "categorias": JSON.stringify(this.categorias.filter(categoria => categoria.selected)),
    }

    this._evalService.updateEval(_syncData, this._hoteldataService.currentConfig.data.test.id)
      .subscribe(res => {
        this._hoteldataService.currentConfig.data.test.saved = true;
        this._router.navigate(['/planning/arrange']);
      });
  }

  toggleCagetory(category: Categoria) {
    category.selected = !category.selected;

    this.secciones.forEach(section => {
      if (section.categoria_padre_id === category.id) {
        section.selected = category.selected;
        if (category.selected) {
          this.addSection(section.id);
        } else {
          this.removeSection(section.id);
        }
      }
    });

    this._hoteldataService.currentConfig.data.secciones = this.ActiveSections;
    this._hoteldataService.currentConfig.data.test.saved = false;
  }

  selectAllSections(): void {
    this.secciones.forEach(sec => {
      sec.selected = true;
      this.addSection(sec.id);
    });
    this._hoteldataService.currentConfig.data.secciones = this.ActiveSections;
    this._hoteldataService.currentConfig.data.test.saved = false;
  }

  removeAllSections(): void {
    this.secciones.forEach(sec => {
      sec.selected = false;
      this.removeSection(sec.id);
    });
    this._hoteldataService.currentConfig.data.secciones = this.ActiveSections;
    this._hoteldataService.currentConfig.data.test.saved = false;
  }

  private addSection(id: string): void {
    if (!this.isSectionPresent(id)) {
      this.ActiveSections.push(id);
    }
  }

  private removeSection(id: string): void {
    const index = this.ActiveSections.indexOf(id);
    if (index !== -1) {
      this.ActiveSections.splice(index, 1);
    }
  }

  private isSectionPresent(id: string): boolean {
    return this.ActiveSections.indexOf(id) !== -1;
  }

  autosave() {
    // Initialize arrays
    let addedSections: Array<string> = [];
    let currentConfigSections: Array<string> = [...this._hoteldataService.currentConfig.data.secciones];

    // Remove sections from currentConfigSections that are not in ActiveSections
    currentConfigSections = currentConfigSections.filter(configSection =>
      this.ActiveSections.includes(configSection)
    );

    // Identify sections in ActiveSections that are not in currentConfigSections
    this.ActiveSections.forEach(activeSection => {
      if (!currentConfigSections.includes(activeSection)) {
        addedSections.push(activeSection);
      }
    });

    // Concatenate currentConfigSections and addedSections to form the final array
    let finalSections = currentConfigSections.concat(addedSections);
    this._hoteldataService.currentConfig.secciones = finalSections;
  }

  async ngOnInit() {
    this.ActiveSections = [];

    this.categorias = await this._hoteldataService.categorias$.pipe(
      map(categorias => categorias.map(categoria => ({ ...categoria, selected: false } as Categoria)))
    ).toPromise();

    this._hoteldataService.secciones$.pipe(
      map(sections => sections.map(section => ({ ...section, selected: true } as Sections)))
    ).subscribe(sections => {
      this.secciones = sections;

      this.secciones.forEach(seccion => {
        seccion.selected = this._hoteldataService.currentConfig.data.secciones.includes(seccion.id);

        if (seccion.selected) {
          this.addSection(seccion.id);
        }
      });
    });

    this._hoteldataService.restore();
    const clave = this._hoteldataService.currentConfig.data.hotel.clave;

    this._evalService.evalIndex(clave).pipe(
          mergeMap((ids: number[]) => this._evalService.evalDetail(clave, ids[0])),
        ).subscribe(
          (evaluation: Evaluation) => {

        // for each evaluation.category mark it as selected this.categorias
        evaluation.categorias?.forEach(categoria => {
          this.categorias.forEach(cat => {
            if (cat.id === categoria.id) {
              cat.selected = true;
            }
          });
        });

        const test_new = {
          id: evaluation.id || null,
          clave: this._hoteldataService.currentConfig.data.test.clave,
          saved: true,
          visita: this._hoteldataService.currentConfig.data.test.visita,
          status: this._hoteldataService.currentConfig.data.test.status
        }

        this._hoteldataService.changer("test", test_new);
      }
    );
  }

}
