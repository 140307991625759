import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-atomic',
  templateUrl: './atomic.component.html'
})
export class AtomicComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
