import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { HotelsearchComponent } from './hotelsearch/hotelsearch.component';
import { SortComponent } from './sort/sort.component';
import { RearrangeComponent } from './rearrange/rearrange.component';
import { SubheaderComponent } from '../sections/subheader/subheader.component';
import { ScoresComponent } from '../widgets/scores/scores.component';
import { LoaderComponent } from '../widgets/loader/loader.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    DragDropModule,
    FormsModule
  ],
  declarations: [
    HotelsearchComponent,
    SortComponent,
    RearrangeComponent,
    SubheaderComponent,
    ScoresComponent,
    LoaderComponent,
  ],
  exports:[
    LoaderComponent
  ]
})
export class PlanningModule { }
