<main class="main app-test" [ngClass]="{scrollOut : summary, offline:!ConnectionStatus}">
  <div class="bg-search bg"></div>
    <div class="test-wrapper" [ngStyle]="{'filter':'blur('+(summary?'3px':'0px')+')'}" [ngClass]='{segundaVisita:visita==2,terceraVisita:visita==3,unactive:visibility}'>
        <header class="test-wrapper-header">
          <figure class="search" (click)="toogleQuestionSearch()">
            <!-- <img src="assets/images/hoteles_logos/fiestainn-logo.png" alt="Logoitpo"> -->
            <i class="icon icon-search"></i>
          </figure>
          <div class="hotel-data-test">

            <div class="read-only">
              <span>{{ hotel_info?.name || 'Cargando' }} </span>
              <span>
                <small>Clave: <span style="color: white;"> {{ hotel_info?.clave || 'Cargando' }} </span></small>
              </span>
            </div>

            <div class="select">
                <span>
                    {{currentSectionData?.name || 'Elige una sección'}}
                </span>
                <div class="arrow-button">
                    <i class="icon icon-arrow-up"></i>
                </div>
                <select #selSection name="section" id="section-pick" (change)="setSection()">
                  <option [value]="sec.clave" *ngFor="let sec of secciones">{{ sec?.nombre }}</option>
                </select>
            </div>

          </div>
          <nav class="hide-menu">
            <div class="menu-button" [ngClass]="{clicked:MenuOn}" (click)="toogleMenu()">
              <div class="bar"></div>
              <div class="bar"></div>
              <div class="bar"></div>
            </div>
          </nav>
        </header>
        <nav class="preguntas-list" (scroll)="navScroll($event)" [ngClass]="{sh_left:navReached}">
          <div class="spacer" [ngStyle]="{'transform':'translateX(calc((52%) - '+questions_desplacement+'px))'}">

            <ng-container *ngFor="let pregunta of preguntas; let i = index">
                <span class="pregunta-circle" *ngIf="pregunta.seccion_clave == currentSection" (click)="makePregunta(pregunta,i)" [ngClass]='{activeQuestion:pregunta.id==currentPreguntaData.id,visitedQuestion:pregunta.checked}'>{{i+1}}
                  <sub>+{{pregunta.valor}}</sub>
                </span>
            </ng-container>

          </div>
          <figure class="current-question"></figure>
        </nav>

        <section class="main-test">

            <div class="answers">
              <div class="right" (click)="answerTheQuestion(true)">
                <span> <small>+{{currentPreguntaData.valor_section}}</small>
                  <svg version="1.1"
                  x="0px" y="0px" viewBox="0 0 131.055 193.467" enable-background="new 0 0 131.055 193.467"
                  xml:space="preserve">
                  <path fill="#1C90A6" d="M0.215,138.145v-16.866H27.5v19.014c0,10.527,0.895,17.923,2.686,22.183
                    c1.789,4.262,5.156,6.392,10.098,6.392s8.218-1.754,9.829-5.264c1.611-3.508,2.417-9.238,2.417-17.188s-1.434-14.878-4.297-20.787
                    c-2.865-5.908-7.412-12.085-13.643-18.53L20.625,92.705c-6.518-6.803-11.585-13.857-15.2-21.162C1.808,64.238,0,55.789,0,46.191
                    C0,30.58,3.008,18.979,9.023,11.387C15.039,3.797,25.387,0,40.068,0c14.68,0,24.616,4.423,29.81,13.267
                    c5.191,8.846,7.788,21.753,7.788,38.726v11.709H51.133V50.166c0-9.166-0.789-15.737-2.363-19.712
                    c-1.576-3.975-4.603-5.962-9.077-5.962c-4.477,0-7.699,1.504-9.668,4.512c-1.971,3.008-2.954,7.52-2.954,13.535
                    s1.002,10.814,3.008,14.395c2.004,3.582,5.621,8.164,10.85,13.75l15.576,16.865C64.309,96,70.2,104.559,74.175,113.223
                    c3.975,8.666,5.962,18.12,5.962,28.36c0,17.689-2.973,30.76-8.916,39.209c-5.945,8.451-16.347,12.676-31.206,12.676
                    c-14.861,0-25.209-4.708-31.045-14.126C3.132,169.925,0.215,156.192,0.215,138.145z"/>
                  <path fill="#1C90A6" d="M102.266,191.319V2.256h28.79v189.063H102.266z"/>
                </svg>
                </span>
              </div>
              <div class="wrong" (click)="answerTheQuestion(false)">
                <span>
                  <svg version="1.1"
                    x="0px" y="0px" viewBox="0 0 196.045 193.467" enable-background="new 0 0 196.045 193.467"
                    xml:space="preserve">
                    <path fill="#666566" d="M0,191.319V2.256h22.559l40.068,113.438l-2.041-51.563V2.256h25.889v189.063h-20.41L24.922,73.906
                      l1.611,49.629v67.784H0z"/>
                    <path fill="#666566" d="M186.162,179.825c-6.59,9.096-17.134,13.643-31.636,13.643s-25.137-4.565-31.904-13.696
                      s-10.151-22.897-10.151-41.304V51.992C112.471,17.332,126.613,0,154.902,0c14.609,0,25.118,4.404,31.528,13.213
                      c6.408,8.809,9.614,21.736,9.614,38.779v86.475C196.045,156.944,192.75,170.731,186.162,179.825z M143.999,161.993
                      c1.682,4.369,5.138,6.553,10.366,6.553c5.227,0,8.664-2.237,10.313-6.714c1.646-4.475,2.471-12.155,2.471-23.042V50.703
                      c0-8.951-0.843-15.485-2.524-19.604c-1.684-4.117-4.979-6.177-9.883-6.177c-4.906,0-8.344,2.06-10.313,6.177
                      c-1.971,4.119-2.954,10.618-2.954,19.497v88.194C141.475,149.891,142.315,157.625,143.999,161.993z"/>
                  </svg>
                </span>
              </div>
            </div>

          <div class="question" [ngClass]="{ok:currentPreguntaData.respuesta && currentPreguntaData.checked,wrong:!currentPreguntaData.respuesta && currentPreguntaData.checked,discard:currentPreguntaData.respuesta=='discard' && currentPreguntaData.checked}">
            <div class="question-data">
              <div class="type-eval">
                <span>
                  <h2>{{visita}}</h2><sub>{{ visita==1?'er': visita==2?'da':'ra'}}</sub>
                </span>
                <small>evaluación</small>
              </div>

              <div class="type-index">
                <small>Pregunta</small>
                <span>
                  <sup>#</sup>
                  <div class="num">{{currentIndex}}</div>
                </span>
              </div>

              <div class="type-data">
                <small><b>Promedio</b></small>
                <small>Categoria: <span style="color:#D2860F;">{{currentSectionData?.total_score}}</span></small>
              </div>

            </div>

            <span #literalQuestionBox class="literal-question" [ngClass]="{scrollableAdvice:overflowed && overflowedNScrolled}" (scroll)="questionScrolled()">
              {{ currentPreguntaData.pregunta }}
            </span>

            <div *ngIf="currentPreguntaData.input_extra" class="fgroup">
              <label for="extraInput">{{ currentPreguntaData.title_input_extra }}</label>
              <!-- if getInputType is an array then is a select if its a string is an input -->
               <div *ngIf="isSelectType(currentPreguntaData.input_extra)">
                 <select id="extraInput" *ngIf="getInputType(currentPreguntaData.input_extra)" [(ngModel)]="extraInput">
                   <option *ngFor="let option of getInputType(currentPreguntaData.input_extra)" [value]="option">{{option}}</option>
                 </select>
               </div>

              <input *ngIf="!isSelectType(currentPreguntaData.input_extra)" id="extraInput" [type]="getInputType(currentPreguntaData.input_extra)" [(ngModel)]="extraInput" />
            </div>

            <span class="descartar" (click)="answerTheQuestion(false,'discard')" *ngIf="visita==1 || !visita">
              <h3>Descartar</h3>
              <small>(No afecta los puntos)</small>
            </span>
          </div>

          <span class="rubrica" *ngIf="visita!==1 && visita ">
            <h3>¿Se resolvió?</h3>
          </span>

          <span class="capex" [ngClass]='{actived:currentPreguntaData.capex}' (click)="addCapex()">
            Capex
          </span>

          <div class="features">
            <span class="photo">
              <i class="icon icon-picture"></i>
              <input #fotosInput type="file" (change)="leerImagenes($event)">
            </span>

            <div class="reactions" (click)="toggleReactions()" [ngClass]="{closedPanel:isOpenReactions}" [ngStyle]="{'background-color':reacColor}">
              <div class="reactions-wrapper" [ngStyle]="{'transform':'translateX('+reacPos+'px)'}">
                <span class="reactions-wrapper-cancel" (click)="reaction('cancel')"><i class="icon icon-frobidden" style="font-size: 1.8rem;"></i></span>
                <!-- <figure class="reactions-wrapper-clock" (click)="reaction('clock')"> </figure> -->
                <figure class="reactions-wrapper-warn" (click)="reaction('warn')"> </figure>
                <!-- <figure class="reactions-wrapper-seen" (click)="reaction('seen')"> </figure> -->
                <figure class="reactions-wrapper-deathly" (click)="reaction('deathly')"> </figure>
              </div>
            </div>
          </div>
        </section>

        <section class="notas">
          <div class="notas-ctrl">
            <span>Notas...</span>
            <div class="new-comment">
              <span class=new-comment-options (click)="toggleCommentWindow()">
                  <i class="icon icon-plus"></i>
                  <i class="icon icon-voice"></i>
              </span>

              <span class="new-comment-bg">
              </span>
            </div>
          </div>

          <div class="notas-canvas" [ngClass]="{empty:currentPreguntaData.notas.length == 0 && currentPreguntaData.fotos.length == 0}">
            <div class="empty-comments">
              <div class="comments" *ngFor="let nota of currentPreguntaData.notas">
                <span (click)="toggleCommentWindow(nota)">{{ nota }}</span>
                <span (click)="deleteComment(nota)"> <i class="icon icon-trash"></i> </span>
              </div>
            </div>

            <div #imgMaster class="empty-images" [ngClass]="{image_viewer:imgVisor}">
              <div class="img-container" *ngFor="let img of currentPreguntaData?.fotos; let i = index;">
                <img [attr.src]="img.url" [ngClass]="{imgMagnified:imgSelection==i+img.name}" (click)="toggleShowImg(i+img.name)">
                <small (click)="cerrarImg(img.name,img.id)"> <i class="icon icon-close"></i> </small>
              </div>
            </div>
          </div>
        </section>

      </div>

      <!-- /////// Question finder /////// -->

      <div class="qFinder-wrapper" *ngIf="showQuestionSearch">
        <div class="questionFinder col">

          <div class="questionFinder-nav row">
            <span class="questionFinder-nav-title"> Búsqueda de preguntas </span>
            <span class="close" (click)="toogleQuestionSearch()">
              <i class="icon icon-close"></i>
            </span>
          </div>

          <div class="questionFinder-head row">
            <label for="Look4questions" class="btn-search">
              <i class="icon icon-search"></i>
            </label>
            <input id="Look4questions" class="input-search" #questionType type="text" placeholder="Palabra clave" (keydown)="key_preguntas()">
            <!-- <span class="mic"> <i class="icon icon-microphone"></i> </span> -->
            <span class="finder close" [ngClass]="{picked:choosenFilter == 'close'}" (click)="filterBy('close')"> <i class="icon icon-close"></i> </span>
            <span class="finder checked" [ngClass]="{picked:choosenFilter == 'checked'}" (click)="filterBy('checked')"> <i class="icon icon-checked"></i> </span>
            <span class="finder forbidden" [ngClass]="{picked:choosenFilter == 'forbidden'}" (click)="filterBy('forbidden')"> <i class="icon icon-frobidden"></i> </span>
          </div>

          <div class="questionFinder-body col" *ngIf="showFilteredQuestions">

            <ng-container *ngFor="let fpregunta of filterPreguntas">
              <div class="questionFinder-body-result col" (click)="flyTo(fpregunta.id)">
                <span class="qf-result-section row"> {{ translateClave(fpregunta.seccion_clave) }} </span>
                <div class="qf-result-info row">

                  <span class="qf-result-info-calificacion">
                    <i class="icon icon-checked" *ngIf="fpregunta.respuesta==true"></i>
                    <i class="icon icon-close" *ngIf="fpregunta.respuesta==false"></i>
                    <i class="icon icon-frobidden" *ngIf="fpregunta.respuesta == 'discard'"></i>
                    <small *ngIf="fpregunta.respuesta == '--'">--</small>
                  </span>

                  <span class="qf-result-info-fragmento"> {{ fpregunta.pregunta }} </span>
                  <span class="qf-result-info-puntos"> {{ fpregunta.valor }} </span>
                </div>
              </div>
            </ng-container>


          </div>

          <span class="no-results" *ngIf="!showFilteredQuestions">Sin resultados</span>

        </div>
      </div>

      <!-- /////// Fixed Comment interface /////// -->

      <div class="commentInput" [ngClass]="{opened:comment_window}">
        <div class="commentInput-bg column">
          <h2>Añada un comentario</h2>

          <textarea #commentInterface name="newComment" id="commentInterface" placeholder="Añade tu comentario aquí" [ngClass]="{recording:_grabando}"></textarea>
          <div class="actions row">
            <button class="button-app row" (click)="newComment()"> <i class="icon icon-edit" *ngIf="editingComment"></i> <i class="icon icon-plus" *ngIf="!editingComment"></i> &nbsp; <span>{{editingComment? 'Conservar/Modificar':'Agregar'}}</span> </button>
            <button class="button-app row" (click)="toggleCommentWindow()"> <i class="icon icon-frobidden"></i> &nbsp; <span>{{editingComment? 'Eliminar':'Cancelar'}}</span> </button>
          </div>
          <div class="record" (click)="grabar()" *ngIf="microphone && !_grabando && ConnectionStatus">
            <i class="icon icon-voice"></i>
          </div>
          <div class="record" (click)="stop()" *ngIf="microphone && _grabando" style="background: red">
            <i class="icon icon-close"></i>
          </div>

        </div>
      </div>

      <!-- /////// Fixed menu /////// -->
      <nav class="slide-menu" [ngClass]="{hidded:!MenuOn,second:visita==2,third:visita==3}">

        <header class="slide-menu-header">
            <span class="back-arrow" (click)="toogleMenu()">
              <i class="icon icon-arrow-r"></i>
            </span>
            <h1>Menú</h1>
        </header>
        <div class="slide-menu-container">
          <span class="slide-menu-container-option" (click)="goToMenu(2)">
            Ordenar secciones
          </span>
          <span class="slide-menu-container-option" (click)="goToMenu(1)">
            Activar secciones
          </span>
          <span class="slide-menu-container-option" (click)="goToMenu(0)">
            Búsqueda de Hoteles
          </span>
          <!-- <span class="slide-menu-container-option" (click)="deleteAllQuestions()">
            Eliminar preguntas
          </span> -->

          <!-- Subir a reportes -->
          <span class="slide-menu-container-option" (click)="syncData(true)">
            <i class="icon icon-sync"></i> Guardar y seguir evaluando
          </span>
          <span class="slide-menu-container-option" (click)="toggleSummary()">
            Revisar reporte
          </span>
          <span class="slide-menu-container-option" (click)="logout()">
            <i class="icon icon-powerOff"></i> Salir
          </span>
        </div>

      </nav>


      <app-summary *ngIf="summary" (updateSectionTest)="setSection($event)" (openSummary)="toggleSummary($event)" (sendQuestion)="makeFromReport($event.sendpregunta,$event.sendseccion)" (eval)="abstracAnswer($event.Value,$event.ID)" (save)="syncData($event)" [playing_score]="hotelScore ||'--'" [limit_score]="hotel_info?.limit_score||'0'" [ngClass]="{showUp:showComponent}"></app-summary>
  </main>
<simple-notifications></simple-notifications>

<app-loader *ngIf="loading"></app-loader>
