import { Injectable } from '@angular/core';
import { User } from '../planning/planning';

@Injectable({
  providedIn: 'root'
})
export class UserdataService {

  users:Array<User> = [
    { userToken:"", user: "amerchant", user_name: "Andre Merchant", mail: "amerchant@forcemsystems.com", puesto:"Desarrollador",NumOfTest: 2, userPass:"Patito123" },
    { userToken:"", user: "pgonzales", user_name: "Pedro Gonzales", mail: "pgonzales@fiho.com", puesto:"Ingeniero",NumOfTest: 7, userPass:"Patito123" },
    { userToken:"", user: "jjimenez", user_name: "Jorge Jimenez", mail: "jjimenez@fiho.com", puesto:"Operaciones",NumOfTest: 34, userPass:"Patito123" },
    { userToken:"", user: "rmartinez", user_name: "Ricardo Martinez", mail: "zapatarage@hotmail.com", puesto:"Mensajería",NumOfTest: 22, userPass:"Patito123" },
    { userToken:"", user: "aflores", user_name: "Alberto Flores", mail: "aflores@fiho.com", puesto:"Director",NumOfTest: 3, userPass:"Patito123" },
  ];

  currentUser:User={ userToken:"", user: "", user_name: "", mail: "", puesto:"",NumOfTest: 0, userPass:""};

  constructor() { }

  makeRandomKey(){
    let random = Math.random();
    return Number(random.toFixed(8)) * 100000000;
  }

}
