<main class="main hotelsearch">
        <div class="bg-search bg"></div>
        <form (submit)="stop($event)">
          <h2>Elija un Hotel</h2>

          <div class="search">
            <div class="search__textbox">
              <i class="icon icon-search"></i>
              <!-- <input type="text" placeholder="Ingresa una dirección" [formControl]="query" (keypress)="onQueryKeypress($event)" /> -->
              <input #searchbar type="text" placeholder="Búsqueda..." (keydown)="key_option($event)" (click)="retrieveHotels()" />
              <!-- <button class="button button--normal" type="button" (click)="onSubmit()">Buscar</button> -->
              <div class="search-box" *ngIf="showSuggests">
                <div class="search-box__suggest-item" *ngFor="let hotel of foundedHotels" (click)="clicked(hotel.clave)" [ngClass]="{choosen:hotel.clave==choosingHotel?.clave&&showSelectable}">
                  {{ hotel?.hotel }}
                </div>
              </div>
            </div>

             <div class="emptiness" *ngIf="!_hoteldataService.currentConfig.data.hotel.clave||_hoteldataService.currentConfig.data.hotel.clave==''">
              <img src="assets/svg/emptyHotel.svg" alt="No hay hoteles" width=300px;>
              <h4>No se ha elegido<br> ningún hotel</h4>
            </div>

            <div class="hotel-info" *ngIf="_hoteldataService.currentConfig.data.hotel.clave !== '' && _hoteldataService.currentConfig.data.hotel.clave">
              <div class="hotel-info__logo">
                  <!-- src="assets/images/hoteles_logos/fiestainn-logo.png" -->
                <img [attr.src]="_selectedHotel.logo" alt="Logotipo">
              </div>
              <h3> {{ _selectedHotel?.name }} </h3>
              <article class="hotel-info__data">

                <div class="hotel-info__data__row">
                  <span class="title">Clave: </span>
                  <span> {{ _selectedHotel?.clave }} </span>
                </div>

                <div class="hotel-info__data__row">
                  <span class="title">Gerente: </span>
                  <span> {{ _selectedHotel?.gerente | titlecase }} </span>
                </div>

                <div class="hotel-info__data__row">
                  <span class="title">Habitaciones: </span>
                  <span> {{ _selectedHotel?.habitaciones }} </span>
                </div>

                <div class="hotel-info__data__row">
                    <span class="title">Segmento: </span>
                    <span> {{ _selectedHotel?.segmento }} </span>
                  </div>
              </article>
            </div>

            <button type="button" [disabled]="_selectedHotel.name == '' || sHotel?.length >= 3" class="button-app" (click)="newTest()">Nueva evaluación</button>
          </div>

        </form>

        <section class="test_glance column" *ngIf="sHotel?.length > 0">
          <div class="test_glance_header row">
            <h3>Cuestionarios</h3>

            <!-- <div class="bars" (click)="toggleScores()">
              <div class="bars-one"></div>
              <div class="bars-two"></div>
              <div class="bars-three"></div>
            </div> -->

            <span class="g-calification">
              <svg xml:space="preserve" xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/" xmlns:xlink="http://www.w3.org/1999/xlink" enable-background="new 0 0 30.793 30.437" height="30.437px" version="1.1" viewBox="0 0 30.793 30.437" width="30.793px" x="0px" xmlns="http://www.w3.org/2000/svg" y="0px">
                <path d="M24.857,30.437l-3.623-11.61l9.56-7.217H19.02L15.397,0l-3.623,11.61H0l9.56,7.217l-3.623,11.61l9.46-7.217
                L24.857,30.437z" [attr.fill]="sHotel?.length >=3 ? '#ffc600':'#999999'"></path>
              </svg>
              <b class="number"> {{ _selectedHotel?.playing_score }}<sub style="font-size: 1rem">pts</sub> </b>
            </span>
          </div>

          <div class="test-set row" *ngFor="let test of sHotel; let i = index;" [ngClass]="{disabled:!test.status}"> 
            <div class="test-set-title column" (click)="EditTest(test.cuestionario_clave,test.id,true,i+1,test.status)">
                <!-- [ngClass]="{unable:blockTestShortcut}" -->
              <div class="frame">
                {{ i + 1 }}. {{ i==0? 'Primer' : i == 1 ? 'Segunda' : 'Tercera' }} visita
              </div>
              <div class="frame-data row">
                <span> P.{{ test?.puntuacion_final.split('-')[0] }} </span>
                <span> <sup>{{ test?.updated_at }} </sup></span>
              </div>
            </div>

            <div class="test-set flex-block-sm text-center-sm row">
              <span class="test-set-edit frame-sub" (click)="EditTest(test.cuestionario_clave,test.id,i!==0,i+1,test.status)">
                <i class="icon icon-edit"></i>
              </span>

              <span class="test-set-block frame-sub" (click)="block(test)" >
                <i class="icon icon-candado-open" *ngIf="test.status"></i>
                <i class="icon icon-candado-closed" *ngIf="!test.status"></i>
                <span class="tip">
                  {{  test?.status ? 'abierto':'cerrado'}} <br>
                </span>
              </span>

              <span *ngIf="test.status" class="test-set-trash frame-sub" (click)="delete(test.id)">
                <i class="icon icon-trash"></i>
              </span>
              <span class="test-set-q-results frame-sub test-set-block" *ngIf="!test.status" (click)="queryResults(test.id)">
                <i class="icon icon-promedio"></i>
              </span>
            </div>

          </div>
        </section>

        <app-scores *ngIf="showScores" [score]="hotelScores" (toggleShow)="toggleScores($event)" ></app-scores>
</main>
<app-loader *ngIf="loading"></app-loader>