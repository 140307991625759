<header>
  <div class="hotel-data">
    <h3>{{SelectedHotel.name}}</h3>
    <div class="hotel-subdata">
      <div class="row">
        <span class="title">Clave: </span> <span>{{ SelectedHotel.clave }}</span>
      </div>
      <div class="row">
          <span class="title">Habitaciones: </span> <span>{{ SelectedHotel.habitaciones }}</span>
        </div>
    </div>
  </div>
  <figure class="logo">
      <img [attr.src]="SelectedHotel.logo" alt="Logotipo">
  </figure>
</header>

<div class="title">
    <h1>
      {{ gn_title }}
    </h1>
</div>
