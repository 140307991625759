import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { testIndex, HotelEvaluationScore } from 'src/app/planning/planning';
import { HoteldataService } from 'src/app/services/hoteldata.service';
import { TestdataService } from 'src/app/services/testdata.service';
import { range, Observable, timer } from 'rxjs';
import { delay, tap, take, map, takeWhile } from 'rxjs/operators';

export interface scoreData {
  categoryName: string
  firstScore : number|string,
  secondScore : number|string,
  thirdScore : number|string,
  categoryAverage: number,
  iconName : string
}

@Component({
  selector: 'app-scores',
  templateUrl: './scores.component.html'
})
export class ScoresComponent implements OnInit {

  scoreTable:Array<scoreData> = [];
  edificioSections: Array<string> = ["2FCHE","3HBTS","4BNHB","5PSHB","6LBRC","7BNPB","8CENG","9SLJS","10GMS","11ALB","12LBR","13RST","14SLN","15FYR","16BOH",];
  equiposSections: Array<string> = ["18CFR","19ARF","20IEL","21LVD","22SEL","23CMQ",];
  started:boolean = false;
  ultimateProm:number = 90.42;

  scoreRows:Array<any> = []

  @Input("tests") Tests : Array<testIndex>;
  @Input("score") scoreResult: HotelEvaluationScore;
  @ViewChild("master", { static: true }) _mainDiv : ElementRef;
  @Output() toggleShow = new EventEmitter<any>();


  constructor(
    private _hotelData : HoteldataService,
    private _testData : TestdataService
  ) {

  }

  async getSectProm(section: string, where: number) {
    let ponderacion: number = 0;
    const questions = await this._testData.preguntas$.toPromise();
    questions.forEach(
      x => {
        if (x.seccion_clave == section) ponderacion += x.valor_section;
      }
    )

    let points: number = 0;
    let preguntas = JSON.parse(this.Tests[where].preguntas);

    preguntas.forEach(x => {
      if (x[0].slice(-5) == section && x[2]) {
        let pregunta = questions.find(w => { return x[0] == w.id });
        points += pregunta.valor_section;
      }
    });
    return [points, ponderacion, Math.round(points / ponderacion * 100)];
  }

  getPercentajeToFixedTwo(number) {
    if(!number) { return 0 }

    var strNumber = number.toFixed(2);
    if(strNumber.split('.')[1] === '00') {
      return parseInt(number);
    }

    return strNumber;
  }

  createTable(){
    var { califiacionesForGroup } = this.scoreResult;

    this.scoreRows=[];

    if (califiacionesForGroup['Edificio']) {
      this.scoreRows.push({
        name:"Edificio",
        eval1: this.getPercentajeToFixedTwo(califiacionesForGroup['Edificio'].true_puntos),
        eval2: this.getPercentajeToFixedTwo(califiacionesForGroup['Edificio'].true_porcentaje),
        categoryAverage:  0,
        icon:"icon-edificio"
      });
    }

    if (califiacionesForGroup['Maquinaría y equipo']) {
      this.scoreRows.push({
        name:"Maquinaría y equipo",
        eval1: this.getPercentajeToFixedTwo(califiacionesForGroup['Maquinaría y equipo'].true_puntos),
        eval2: this.getPercentajeToFixedTwo(califiacionesForGroup['Maquinaría y equipo'].true_porcentaje),
        categoryAverage:  0,
        icon:"icon-equipo"
      });
    }

    if (califiacionesForGroup['Administración']) {
      this.scoreRows.push({
        name:"Admon",
        eval1: this.getPercentajeToFixedTwo(califiacionesForGroup['Administración'].true_puntos),
        eval2: this.getPercentajeToFixedTwo(califiacionesForGroup['Administración'].true_porcentaje),
        categoryAverage: 0,
        icon:"icon-admon"
      });
    }

    if (califiacionesForGroup['Seguridad humana']) {
      this.scoreRows.push({
        name:"Seguridad humana",
        eval1: this.getPercentajeToFixedTwo(califiacionesForGroup['Seguridad humana'].true_puntos),
        eval2: this.getPercentajeToFixedTwo(califiacionesForGroup['Seguridad humana'].true_porcentaje),
        categoryAverage:  0,
        icon:"icon-riesgos"
      });
    }
  }

  ngOnInit() {
    this.createTable();
    let lastValue = this.ultimateProm;
    let source = range(0,lastValue);

    let counter$: Observable<number>;
    let count = lastValue;

    this.started=true;
    let objectData = this._mainDiv.nativeElement.getBoundingClientRect();

    window.scrollTo({
      left:0,
      top: objectData.top,
      behavior: 'smooth'
    })

    counter$ = timer(0,50).pipe(
      takeWhile(x=> x==lastValue)
    )

    counter$.subscribe(
      res=> {
        this.ultimateProm = res;
      },()=>{console.log("this has ended")}
    )

  }

}
