<main class="main login">
  <div class="bg-login bg"></div>

  <form [formGroup]="login" (ngSubmit)="submitLogin()">
    <h2>Ingrese su usuario</h2>

    <article class="fields">

      <div class="fields__user" [ngClass]="{inputError:emailInput}">
        <input type="text" name="email" placeholder="" formControlName="email" (keydown)="checkUser()" required [ngClass]="{filled:email!==''}">
        <span class="input_title">Usuario</span>
        <i class="icon icon-arrow-r"></i>
      </div>

      <div class="fields__password" [ngClass]="{inputError:passInput}">
        <input type="password" name="password" placeholder="" formControlName="password" (keydown)="checkPassword()" required [ngClass]="{filled:password!==''}">
        <span class="input_title">Contraseña</span>
        <i class="icon icon-shield"></i>
      </div>
      <small class="errors"> {{ message }} </small>
      <br>

      <button type="submit">Entrar</button>
    </article>

    <!-- <button (click)="tryUDetails()">Try user</button> -->

    <svg class="lines" version="1.1" [ngClass]="{enabled:lines}"
    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/"
    x="0px" y="0px" width="160.939px" height="356.672px" viewBox="0 0 312.939 576.672" enable-background="new 0 0 312.939 576.672"
    xml:space="preserve">
    <path fill="none" stroke="#F2F2F2" stroke-width="3" stroke-miterlimit="10" d="M22.246,42.965
    C33.559,42.814,42.94,33.569,42.94,22.22c0-11.443-9.277-20.72-20.72-20.72S1.5,10.777,1.5,22.22S10.803,42.965,22.246,42.965
    L22.246,42.965L22.22,575.172h290.719"/>
    </svg>

  </form>

</main>
