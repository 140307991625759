import { Component, OnInit } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { transition, trigger, query, style, animate, group, animateChild } from '@angular/animations';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  animations: [
    trigger('SlideTransition', [
      transition('* <=> *', [
        query(':enter, :leave', style({ position: 'fixed', width:'100%',overflow:'hidden' })
          , { optional: true }),
        group([
          query(':enter', [
            style({ transform: 'translateX(100%)' }),
            animate('380ms ease', style({ transform: 'translateX(0%)' }))
          ], { optional: true }),
          query(':leave', [
            style({ transform: 'translateX(0%)' }),
            animate('380ms ease', style({ transform: 'translateX(-100%)' }))
          ], { optional: true }),
        ])
      ])
    ])

  ]
})
export class AppComponent implements OnInit{

  constructor(
    private swUpdate:SwUpdate,
    ){
  }

  ngOnInit(){
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
          if(confirm("Nueva versión disponible. ¿Desea descargar la nueva versión?")) {
              window.location.reload();
          }
      });
    }
  }

}
