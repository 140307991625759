import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserdataService } from '../services/userdata.service';
import { User } from '../planning/planning';
import { HoteldataService } from '../services/hoteldata.service';
import { AuthService } from '../rest/auth/auth.service';
import { NotificationsService } from 'angular2-notifications';
import { HotelsService } from '../rest/hotels.service';
import { mergeMap, pluck, tap } from 'rxjs/operators';
import { data_user } from '../services/current-config';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {
  lines:boolean = false;
  login:UntypedFormGroup;
  message:string = "";
  emailInput=false;
  passInput=false;

  get email(){
    return this.login.get('email').value;
  }

  get password(){
    return this.login.get('password').value;
  }

  constructor(
    private _formBuilder:UntypedFormBuilder,
    private _router:Router,
    private _userData : UserdataService,
    private _hotelData : HoteldataService,
    private _hotelService : HotelsService,
    private _authService : AuthService,
    public _notifications: NotificationsService,
  ) {
    this.login = _formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    })
  }

  checkUser(){
    if(this.email=="" || this.email.length < 3 ) this.emailInput = true;
    if(this.email.length > 3) this.emailInput = false;
  }

  checkPassword(){
    if(this.password=="" || this.password.length < 3) this.passInput = true;
    if(this.password.length > 3) this.passInput = false;
  }

  userTry:boolean =false;
  passTry:boolean = false;

  setupUser(x:any){
    // localStorage.clear();

    const dUser:data_user = {
      user_id : x.data_user.id,
      user_name : x.data_user.name,
      user_mail : x.data_user.email,
      user_eval_count : x.data_user.count_evaluaciones,
      user_avatar : x.data_user.avatar,
      user_cargo : x.data_user.cargo,
    }

    let noLSdata = localStorage.getItem("fibraSettings") == null;

    if(noLSdata) {
      this._hotelData.LSpreset.user = dUser;
      localStorage.setItem("fibraSettings",JSON.stringify(this._hotelData.LSpreset));
    }else this._hotelData.changer("user",dUser);
  }

  validateUser(){
    if(this.email!== ""){
      if(this.password==""){
        this.message = "Ingrese su contraseña";
        return;
      }

      this._authService.login(this.login.value)
      .pipe(
        pluck("user_id"),
        mergeMap((usrId:number)=> this._hotelService.userDetails(usrId)),
        tap( x =>{
          this.setupUser(x);
        })
      ).subscribe(ret=>{
        this.goOn();
        this._notifications.success(
          "Inicio exitoso",//title
          "Usuario FIHO detectado",//content
          this._authService.settings //override
        );
      }, error => {
        // console.log()
        this.message = "Contraseña y/o usuario incorrectos";
        this._notifications.warn(
          "Precaución",//title
          "Datos de sesión incorrectos",//content
          this._authService.settings //override
        );
      });

    }else{
      this.message = "Ingrese un usuario";
    }
  }

  goOn(){

    this.message = "¡Ingreso correcto!";
    let user:User = {
      user: "00001",
      // user_name:this.email.split('@')[0],
      user_name:this.email,
    }

    // this._hotelData.currentConfig.user = user.user_name;
    // this._userData.currentUser = user;
    // this._userData.currentUser.userToken = Math.random()*100000;

    this.lines = false;
    setTimeout(() => {
      this._router.navigate(['/planning']);
    }, 620);
  }

  submitLogin() {
    this.validateUser();
  }

  // tryUDetails(){
  //   this._hotelService.userDetails(1)
  //   .subscribe(
  //     res=>{console.log(res)},
  //     err=>{console.log(err)}
  //   )
  // }

  ngOnInit() {

    let data = sessionStorage.getItem('access_token');
    if(data) this._router.navigate(['/planning']);

    setTimeout(() => {
      this.lines = true;
      this._hotelData.restore()
      //console.log(this._hotelData.currentConfig.data.user)
    }, 500);
  }

}
