<main class="column">
    <h1>Abstracto</h1>

    <div class="scores row">
      <h3> {{playingScore}} </h3>
      <em>de</em>
      <h3> {{limitScore}} </h3>
    </div>

    <div class="section-sel">
      <label for="s_seccion"><i class="icon icon-arrow-up"></i></label>
      <select id="s_seccion" #selectorsec name="secciones" (change)="checkForPregs()" palceholder="sección">
        <option [attr.value]="seccion" *ngFor="let seccion of summ_secciones">{{ nameSection(seccion) }}</option>
      </select>
    </div>

    <small>
      Llenar preguntas sin contestar:
    </small>

    <div class="row">
        <button (click)="yesToAll(actualSec)">En esta sección</button>
        <button (click)="yesToAll()">En este cuestionario</button>
    </div>

    <div class="column preguntas-container">
      <div class="row pregunta" *ngFor="let pregunta of summ_preguntas" [ngStyle]="{'opacity':pregunta.respuesta==='discard'?'.35':1}">
        <sup [ngStyle]="{'opacity':pregunta.respuesta===false?'.35':1}">{{pregunta.valor}}</sup>
        <span class="summ-preg" (click)="sendPregunta(pregunta)">{{pregunta.pregunta}}</span>

        <span class="resp-btn" *ngIf="pregunta.respuesta!=='discard'" [ngClass]="{wrong:pregunta.respuesta===false}" (click)="changeCalification(false,pregunta.id)">
           <i class="icon icon-close"></i>
        </span>
        <span class="resp-btn" *ngIf="pregunta.respuesta!=='discard'" [ngClass]="{right:pregunta.respuesta===true}" (click)="changeCalification(true,pregunta.id)">
           <i class="icon icon-checked"></i>
        </span>
        <span class="resp-btn" *ngIf="pregunta.respuesta!=='discard'" [ngClass]="{discard:pregunta.respuesta==='discard'}" (click)="changeCalification('discard',pregunta.id)">
          <i class="icon icon-frobidden"></i>
       </span>
        <h4 *ngIf="pregunta.respuesta=='discard'">**Descartada**</h4>

      </div>
    </div>

    <h4 *ngIf="summ_preguntas.length==0">
      Aún no has respondido preguntas de esta sección
    </h4>

    <div class="row last-options">
      <button (click)="getOut()">Volver</button>
      <button (click)="sendReporte()"> Envíar reporte</button>
    </div>
</main>
