import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { HoteldataService } from '../services/hoteldata.service';
import { Evaluation } from '../planning/planning';

export interface syncData {
  id?: number,
  cuestionario_clave?: string,
  hotel_clave?: string,
  visita?: number,
  secciones?: string,
  preguntas?: string,
  status?: boolean,
  puntuacion_final?: string,
  user_id?: number,
  version?: number;
}

export interface loadPhoto{
  hotel_clave:string,
  cuestionario_clave:string,
  pregunta_clave:string,
  name:string,
  base64:string,
}

@Injectable({
  providedIn: 'root'
})
export class EvalService {

  syncData : syncData;

  constructor(
    public _httpClient: HttpClient,
    public _hotelData: HoteldataService
  ) {
    this._hotelData.restore();

    this.syncData = {
      cuestionario_clave: this._hotelData.currentConfig.data.test.clave,
      hotel_clave: this._hotelData.currentConfig.data.hotel.clave,
      visita: this._hotelData.currentConfig.data.test.visita||0,
      secciones: JSON.stringify(this._hotelData.currentConfig.data.secciones) || "''",
      preguntas: JSON.stringify(this._hotelData.currentConfig.data.preguntas) || "''",
      status: this._hotelData.currentConfig.data.test.status,
      puntuacion_final: `${this._hotelData.currentConfig.data.playing_score} - ${this._hotelData.currentConfig.data.limit_score}`,
      user_id: this._hotelData.currentConfig.data.user.user_id,//actualizar user id
    }
  }

  deleteTest(id:number){
    return this._httpClient.get(`${environment.destroyTest}/${id}`);
  }

  getResults(id: number) {
    return this._httpClient.get(`${environment.evalResult}/${id}`);
  }

  uploadEval(obj:syncData){
    // console.log("EVAL CREADO");
    return this._httpClient.post(environment.eval, { ...obj, version: 2 });
  }

  evalDetail(clave:string,key:number|string) {
    return this._httpClient.get<Evaluation>(`${environment.eval}/${clave}/${key}`);
  }

  updateEval(obj:syncData,key:number|string){
    return this._httpClient.patch(`${environment.eval}/${key}/update`, { ...obj, version: 2 });
  }

  reporte(obj:syncData){
    return this._httpClient.post(environment.reporte,obj);
  }

  evalIndex(clave:string) {
    return this._httpClient.get<number[]>(`${environment.api}/evaluaciones/hotel/${clave}`);
  }

  uploadPhoto(imgData:loadPhoto){
    return this._httpClient.post(environment.photo,imgData);
  }

  removePhoto(id:number){
    return this._httpClient.get(`${environment.removePhoto}/${id}`);
  }


}
