export interface User {
  user:string,
  userToken?:any,
  userPass?:any,
  user_id?:number | string,
  user_name:string,
  mail?:string,
  puesto?:string,
  NumOfTest?:number,
}

export interface testIndex {
  created_at?:string,
  cuestionario_clave?:string,
  hotel_clave?:string,
  id?:number,
  puntuacion_final?:string,
  updated_at?:string,
  user_id?:number,
  visita?:number,
  status?:string,
  preguntas?:any,
  secciones?:any,
}

export interface HotelEvaluationScore {
  califiacionesForGroup: any,
  calificacionGlobal: any,
}

export interface Hotels{
  id:number,
  name:string,
  edad?:number,
  clave?:string,
  gerente?:string,
  habitaciones?:number,
  limit_score?:number,
  playing_score?:number
  logo?:string
}

export interface Sections {
  id: string,
  categoria_padre_id: number,
  group: string,
  name: string,
  total_score_subarea: number,
  total_score: number,
  selected?: boolean
}

export interface Categoria {
  id: number,
  name: string,
  selected?: boolean
}

export interface Evaluation {
  id: number;
  cuestionario_clave: string;
  hotel_clave: string;
  visita: number;
  secciones: string[];
  preguntas: string[];
  status: string;
  puntuacion_final: string;
  user_id: number;
  created_at: string;
  updated_at: string;
  year: number;
  version: string;
  categorias: Categoria[];
}

export interface Test {
  clave?:string,
  hotel_clave?:string,
  status?:boolean, //bloqueado || activo
  total_score?:number,
  current_score?:number,
  date?:any,
  user_id?:string|number,
  puntuacion_final?:any
}

