import { Injectable } from '@angular/core';
import { UserdataService } from './userdata.service';
import * as moment from 'moment';
import 'moment/locale/es';
import { Subject } from 'rxjs';
import { HoteldataService } from './hoteldata.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map, share, shareReplay } from 'rxjs/operators';
import { Answer } from './current-config';

export interface test{
  id:any,
  hotel_clave:string,
  visita:number,//1,2,3
  status?:boolean|string, //{'bloqueado','activo'}
  puntuacion_final?:number|string,
  playing_score?:number,
  limit_score?:number //-> total de la suma del puntaje,
  preguntas?:string;
  secciones?:string
  fecha?:any,
  usuario:string
}

export interface foto{
  name:string,
  url:string,
  id?:number
}



export interface storedTest {
  id: string | number,
  hotel_clave: string,
  visita: number,
  secciones : Array<string>,
  playing_score: number,
  limit_score: number,
  fecha: string,
  usuario: string,
  preguntas: Array<Answer>
}

export interface Pregunta {
  id: string;
  seccion_clave: string;
  valor: number;
  pregunta: string;
  title_input_extra: string | null;
  input_extra: string | null;
  valor_section: number;
  valor_subsection: number | null,
  subsection: string;
};

export interface Question extends Pregunta {
  cuestionario_id?: any,
  checked?: boolean,
  marca?: number, //rubrica de gravedad
  respuesta: boolean | string,
  notas?: Array<string>,
  fotos?: Array<foto>,
  hora: string,
  fecha: string,
  capex: boolean
}

@Injectable({
  providedIn: 'root'
})
export class TestdataService {

  currentCuestionarioChange:Subject<any> = new Subject<any>();
  savedTestChange:Subject<any> = new Subject<any>();
  savedTest: Array<storedTest> = [];

  constructor(
    public _hoteldata : HoteldataService,
    private _userData : UserdataService,
    private httpClient: HttpClient
  ) {
    this.currentCuestionarioChange
      .subscribe( () => {
        localStorage.setItem("cuestionarios",JSON.stringify(this.Cuestionarios));
    });

    this.savedTestChange
      .subscribe( ()=>{
        this.checkStoredData();
    });
  }

  preguntas$ = this.httpClient.get<Pregunta[]>(`${environment.api}/2/getPreguntas`).pipe(
    shareReplay()
  );

  // checked , marca , respuesta, hora, fecha, capex, notas
  initializePregunta(pregunta): Question {
      return {
          ...pregunta,
          checked: false,
          marca: 0,
          respuesta: false,
          hora: "",
          fecha: "",
          capex: false,
          notas: []
      };
  }

  questions$ = this.preguntas$.pipe(
    map(preguntas => preguntas.map(this.initializePregunta))
  );


  testChanges(){
    this.currentCuestionarioChange.next();
    //let a = document.addEventListener("change",function(event){console.log(event)})
  }

  saveChanges(){
    this.savedTestChange.next();
  }

  testRestore(){
    let ltest = localStorage.getItem("cuestionarios");
    if(ltest){
      this.Cuestionarios = JSON.parse(ltest);
    }
  }


  Cuestionarios:Array<test>=[
    //{id:"f70BU", hotel_clave:"FIGDL",visita:3,status:true,puntuacion_final:0,fecha:"",usuario:"amerchant"},
  ];

  //cambiar localStorage a httpRequest via REST API
  uploadData(data:storedTest){
    this.saveChanges();
    let fd:storedTest = this.savedTest.find( stest => { return stest.id == data.id });
    let index:number = this.savedTest.indexOf(fd);

    if(index == -1) this.savedTest.push(data);
    else this.savedTest[index] = data;

    localStorage.setItem("stored",JSON.stringify(this.savedTest));
    this.savedTestChange.next();

  }

  checkStoredData(){
    let saved = localStorage.getItem("stored");
    // console.log("saved",saved);

    if(!saved) return;
    this.savedTest = JSON.parse(saved);
    this.savedTest.forEach( test =>{
      this.generateNewTest(test.hotel_clave,true,test.hotel_clave,test.visita,test.usuario, test.fecha,test.playing_score,test.limit_score);
    });
    //console.log("savedTest",this.savedTest);
  }


  reviewCuestionarios(h_clave){
    let sameHotel : Array<test> = [];
    this.Cuestionarios.forEach(cuestionario => {
      if (cuestionario.hotel_clave == h_clave) sameHotel.push(cuestionario);
    })
    return sameHotel;
  }

  generateNewTest(_hotel_clave:string,exist:boolean,id?:string,visita?:number,user?:string,fecha?:string,pscore?:number,lscore?:number){
    this._hoteldata.restore();

    let _sameHotel = this.reviewCuestionarios(_hotel_clave);

    let genTest:test = {
      id: exist? id : "TS"+_hotel_clave[0]+_hotel_clave[1]+this._userData.makeRandomKey(),
      hotel_clave: _hotel_clave,
      visita:0,//por cambiar
      status:true,
      playing_score: exist ? pscore : 0,
      limit_score: exist ? lscore : 0,
      fecha: exist ? fecha : moment().format('DD[/]MM[/]YYYY'),
      usuario: this._hoteldata.currentConfig.data.user.user_mail,
    }

    switch (_sameHotel.length) {
      case 0:
        genTest.visita = 1;
      break;
      case 1:
        genTest.visita = 2;
      break;
      case 2:
        genTest.visita = 3;
      break;
      case 3:
        //console.log("No se pueden añadir más de 3 tests");
      break;
    }

    if(exist){
      genTest.visita = visita;
      return [true,genTest.id];
    }

    if(_sameHotel.length < 3){
      this.Cuestionarios.push(genTest);
      return [true,genTest.id];
    }
  }

  deleteSavedData(eraseId:string){
    this.saveChanges();
    let erase = this.savedTest.find( stest => { return stest.id==eraseId});
    let indexErase = this.savedTest.indexOf(erase);

    this.savedTest.splice(indexErase,1);
    localStorage.setItem("stored",JSON.stringify(this.savedTest));
  }

  strdData(){
    let cconfig = this._hoteldata.currentConfig.data;
    let cuestionario = this.Cuestionarios.find( c => { return c.id == cconfig.test.id} );

    if(!cuestionario) return;

    //Almacenar cambios
    let storeDB:storedTest = {
      id: cconfig.test.id,
      hotel_clave: cconfig.hotel.clave,
      visita: cuestionario.visita,
      secciones : cconfig.secciones,
      playing_score: cconfig.playing_score,
      limit_score: cconfig.limit_score,
      fecha: moment().format('DD[/]MM[/]YYYY'),
      usuario: cuestionario.usuario,
      preguntas: cconfig.preguntas
    }

    this.uploadData(storeDB);
  }

  async resetPreguntas() {
    this._hoteldata.currentConfig.preguntas = [];
    const preguntas = await this.questions$.toPromise();

    preguntas.forEach(pregunta => {
      pregunta.checked = false;
      pregunta.marca = 0;
      pregunta.hora = "",
      pregunta.fecha = "",
      pregunta.capex = false,
      pregunta.notas = []
    });
  }
}
