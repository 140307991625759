import { Injectable } from '@angular/core';
import { Categoria, Hotels, Sections } from '../planning/planning';
import { CurrentConfig, data } from '../services/current-config'
import { Subject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HoteldataService{

  forceUpload : Subject<any> = new Subject<any>();
  configChanged : Subject<any> = new Subject<any>();
  currentConfig: CurrentConfig;

  categorias$ = this.httpClient.get<Categoria[]>(`${environment.api}/2/getCategoriasPadre`).pipe(
    shareReplay()
  );

  secciones$ = this.httpClient.get<Sections[]>(`${environment.api}/2/getCategoriasPreguntas`).pipe(
    shareReplay()
  );

  constructor(private httpClient: HttpClient) {
    this.currentConfig = new CurrentConfig();

    const handleSaveLocalData = (res: any) => this.saveLocalData(res);

    this.currentConfig.changes.subscribe(handleSaveLocalData);
    this.forceUpload.subscribe(handleSaveLocalData);
  }

  LSpreset:data={
    user:{},
    limit_score:0,
    playing_score:0,
    secciones:[],
    hotel:{},
    test:{},
    preguntas:[],
    lastVisit:''
  }

  saveLocalData(val){
    let dataMod = JSON.stringify(val);
    localStorage.setItem("fibraSettings",dataMod);
  }

  //update config with localStorage data
  restore() {
    let lconfig = localStorage.getItem("fibraSettings");

    if (lconfig !== "") {
      this.currentConfig.data = JSON.parse(lconfig);
      return true;
    } else {
      return false;
    }
  }

  changer(property: string, insert: any) {
    this.currentConfig[property] = insert;
    this.configChanged.next(true);
  }

  async makeLimitScore(sectionsKeys: Array<string>) {
    const secciones = await this.secciones$.toPromise();

    return sectionsKeys.reduce((sum, sectionKey) => {
      let section = secciones.find(s => s.id === sectionKey);

      if (section) {
        return sum + section.total_score;
      }

      return sum;
    }, 0);
  }

  Hoteles:Array<Hotels> = [];
}
