import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { HoteldataService } from '../services/hoteldata.service';
import { TestdataService, Question, foto } from '../services/testdata.service';
import { Sections, Hotels } from '../planning/planning';
import * as moment from 'moment';
import 'moment/locale/es';
import daycaca from 'daycaca';
import { Subject, Observable, fromEvent } from 'rxjs';
import { HotelsService } from '../rest/hotels.service';
import { EvalService, loadPhoto } from '../rest/eval.service';
import { Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { AuthService } from '../rest/auth/auth.service';
import { pluck, tap } from 'rxjs/operators';
import { Answer, data_test } from '../services/current-config';
import { environment } from 'src/environments/environment';

export interface IWindow extends Window {
  webkitSpeechRecognition: any;
}

export interface key_sel{
  nombre : string,
  clave : string,
  puntos : number,
}

export interface file{
  lastModified: number,
  name: string,
  size: number,
  type: string,
  webkitRelativePath: string​,
}

const {webkitSpeechRecognition} : IWindow = <IWindow><unknown>window;

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})
export class TestComponent implements OnInit {
  //static
  questionCircle:number = 36;
  reacColor:string = "#c5d9ad";
  currentIndex=1;
  MenuLinks: Array<string> = ["/planning","/planning/sort",'/planning/arrange','/'];

  //Booleans||style
  navReached:boolean = false;
  MenuOn:boolean = false;
  isOpenReactions:boolean = true;
  overflowed:boolean = false;
  overflowedNScrolled:boolean = false;
  comment_window:boolean = false;
  imgVisor:boolean = false;
  questions_desplacement:number = this.questionCircle;
  microphone:boolean;
  _grabando:boolean=false;
  saveCount:boolean=false;
  visibility:boolean;
  summary:boolean=false;
  showComponent:boolean = false;
  loading:boolean=false;

  $online:Observable<object>;
  $offline:Observable<object>;

  ConnectionStatus:boolean;

  //Data
  reacPos:number=0;
  hotel_info:Hotels;
  limit_score:number=0;
  playing_score:number=0;
  visita:number=1;
  secciones:Array<key_sel> = [];
  preguntas: Array<Question>;
  brenchSection:Array<Question> = [];

  laggedImages:Array<any> = [];


  currentPreguntaData : Question = {
    id: "001",
    seccion_clave: "Loading...",
    checked: false,
    pregunta: "Loading...",
    marca: 0,
    valor_section: null,
    respuesta: false,
    hora: "000",
    fecha: "00",
    capex: false,
    notas: [],
    fotos: [],
    valor: 0,
    title_input_extra: '',
    input_extra: '',
    valor_subsection: 0,
    subsection: ''
  };

  get hotelScore(){
    this._hoteldata.restore();
    return this._hoteldata.currentConfig.data.playing_score;
  }

  set hotelScore(val:number){
    this._hoteldata.currentConfig.playing_score = val;
  }

  //Value unset
  currentSectionData:Sections;
  recognition;
  userDictation;
  waitForIt:any;

  // extra input
  extraInput:string = "";

  constructor(
    private _router: Router,
    public _hoteldata: HoteldataService,
    public _testdata: TestdataService,
    private _hotelService: HotelsService,
    public _notifications: NotificationsService,
    public _authService: AuthService,
    private _evalService: EvalService,
  ) {
    this.$online = fromEvent(window, 'online');
    this.$offline = fromEvent(window, 'offline');
    this.ConnectionStatus = navigator.onLine;

    const handleConnectionChange = (res: string) => {
      this.ConnectionStatus = res === "online";
      if (this.ConnectionStatus) {
        this.loadLaggedImages();
        setTimeout(() => this.syncData(true), 500);
      }
    };

    this.$online.pipe(pluck("type")).subscribe(handleConnectionChange);
    this.$offline.pipe(pluck("type")).subscribe(handleConnectionChange);

    this.visibility = document.hidden;
    this.microphone = 'webkitSpeechRecognition' in window;
    if (this.microphone) this.initRecognition();

    this._evalService.evalDetail(
      this._hoteldata.currentConfig.data.hotel.clave,
      this._hoteldata.currentConfig.data.test.id
    ).pipe(pluck("preguntas")).subscribe((res: any) => {
      if (res) {
        const preguntas = JSON.parse(res);
        if (preguntas.length) {
          this._hoteldata.currentConfig.preguntas = preguntas;
        }
      }
    });

    this._hoteldata.restore();
    this.getHotelData(this._hoteldata.currentConfig.data.hotel.clave);

    if (!this._hoteldata.currentConfig.data.hotel.clave) {
      this._router.navigate(['/planning']);
    }

    this.initTest(true);
  }

  goToMenu(link:number){
    if(!this.ConnectionStatus){
      if(!confirm("No está conectado a internet y podría perder datos, ¿desea continuar?"))return;
    }
    this.syncData(false);
    this._router.navigate([`${this.MenuLinks[link]}`]);
  }

  final_transcript:string = "";
  dictationChange:Subject<any> = new Subject<any>();

  initRecognition(){
    this.recognition = new webkitSpeechRecognition();
    this.microphone = true;
    this.recognition.continuous = true;
    this.recognition.interimResults = true;
    this.recognition.lang = 'es-MX';

    this.recognition.onstart = ()=>{
      this.final_transcript = "";
      this.statusGrabacion="started";
    }

    this.recognition.onend = ()=>{
      this.statusGrabacion="ended";
    }

    this.recognition.onerror = event =>{
      console.log(event);
    }

    this.recognition.onresult = event =>{
      let interim_transcript:string = "";
      let final:boolean = false;

      for (var i = event.resultIndex; i < event.results.length; i++) {
        if (event.results[i].isFinal) {
          console.log("isFinal x1?",event.results[i].isFinal);
          final=true;
          this.final_transcript = event.results[i][0].transcript;
        } else {
          console.log("isFinal x2?",event.results[i].isFinal);

          final=false;
          interim_transcript += event.results[i][0].transcript;
        }
      }
      this.final_transcript = this.capitalize(this.final_transcript);

      this.nativeCommentInterface.nativeElement.value =final?this.final_transcript:interim_transcript;
      this.textoGrabado = final?this.final_transcript:interim_transcript;

      console.log("final_transcript: ",this.final_transcript);
    }
  }

  grabar(){
    try{
      this.recognition.start();
      this._grabando = true;
    } catch (msg) {
      console.log("Recognition error:",msg.message);
    }
  }

  stop(){
    this.recognition.stop();
    this._grabando = false;
  }

  capitalize(string:string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  @ViewChild('commentInterface', { static: true }) nativeCommentInterface : ElementRef;
  //Detect overflowed text
  @ViewChild('literalQuestionBox', { static: true }) nativeLiteralquestionbox : ElementRef;

  logout(){

    if(!this.ConnectionStatus){
      console.log("this.ConnectionStatus",this.ConnectionStatus);
      if(confirm("No está conectado a internet y podría perder datos, ¿desea continuar?")) {
        this._authService.logout();
      }
    }else{
      this.syncData(false)
      this._authService.logout();
    }

  }

  async syncData(showAdvice?:Boolean){ // Añadir total score
    if(this.saveCount) { window.clearTimeout(this.waitForIt); }
    this.saveCount=false;


    this._hoteldata.restore();
    //this._testdata.testRestore();
    this.totalScore();
    let data = this._hoteldata.currentConfig.data;

    if(!data.user||!data.hotel.clave||data.test.id==undefined){
      console.log("Retorned");
      return;
    }

    let _syncData = {
      //cuestionario_clave: this._hoteldata.currentConfig.data.test.clave,
      //hotel_clave: this._hoteldata.currentConfig.data.hotel.clave,
      //visita: this._hoteldata.currentConfig.data.test.visita||0,
      secciones: JSON.stringify(this._hoteldata.currentConfig.data.secciones) || "''",
      preguntas: JSON.stringify(this._hoteldata.currentConfig.data.preguntas) || "''",
      //status: this._hoteldata.currentConfig.data.test.status,
      puntuacion_final: `${this._hoteldata.currentConfig.data.playing_score} - ${this._hoteldata.currentConfig.data.limit_score}`,
      //user_id: this._hoteldata.currentConfig.data.user.user_id ,//actualizar user id
    }
    //console.log("_syncData",_syncData);

    const ls_test:data_test = {
      id:data.test.id,
      clave:this._hoteldata.currentConfig.data.test.clave,
      saved:true,
      visita:this._hoteldata.currentConfig.data.test.visita,
      status:this._hoteldata.currentConfig.data.test.status,
    }

    this._evalService.updateEval(_syncData,data.test.id)
    .subscribe(
      res=>{
        let server_data:any = Object.keys(res)[0];

        console.log("res",server_data)
        console.log("raw res",res);

        if(server_data=="status"){

          this._notifications.warn(
            "Advertencia",//title
            "La sesión ha caducado",//content
            this._authService.settings //override
          );

        }else{

          if(showAdvice){
            this._notifications.success(
              "Guardado",//title
              "Los datos se sincronizaron",//content
              this._authService.settings //override
            );
            this._hoteldata.currentConfig.test.saved = true;
          }
          this._hoteldata.currentConfig.test.saved=true;
          this._hoteldata.changer("test",ls_test);

        }


      },
      err=>{
        console.log("error",err);
        this._notifications.error(
          "Error al guardar",//title
          "Imposible sincronizar sin internet o sesión",//content
          this._authService.settings //override
        );
      }
    )

    this.MenuOn = false;
  }

  questionScrolled(){
    setTimeout(() => {
      let lbox = this.nativeLiteralquestionbox.nativeElement;
      this.overflowedNScrolled=lbox.scrollTop == 0;
    }, 300);
  }

  getHotelData(clave?:string){
    let Hotel:Hotels;
    this._hotelService.hotelDetails(clave)
    .subscribe( details => {

      if(!details[0]){
        //this._authService.logout();
        this._notifications.warn(
          "Ojo",//title
          "No se recuperaron datos, intenta salir y entrar de nuevo",//content
          this._authService.settings //override
        );
      }else{
        Hotel = {
          id:details[0].id,
          name:details[0].hotel,
          clave:details[0].clave,
          edad:0,
          limit_score: this._hoteldata.currentConfig.data.limit_score,
          playing_score: this._hoteldata.currentConfig.data.playing_score,
          gerente:details[0].gerente_general,
          habitaciones:details[0].habitaciones,
          logo:`${environment.Protocol}://${environment.Host}/assets/images/logos/${details[0].logo}`,
        }
        this._hoteldata.currentConfig.hotel = Hotel;
        this.hotel_info = Hotel;
        this.make_totalScore();
      }

    });
  }

  //Manage input section info
  @ViewChild('selSection', { static: true }) nativeSelSection: ElementRef;
  _currentSelection:string;

  get currentSection(){
    this._currentSelection = this.nativeSelSection.nativeElement.value;
    return this._currentSelection;
  }

  set currentSection(val:string){
    this._currentSelection = val;
    this.nativeSelSection.nativeElement.value = this._currentSelection;
  }

  async initTest(loadSections?:boolean){
    const secciones = await this._hoteldata.secciones$.toPromise();
    const questions = await this._testdata.questions$.toPromise();
    this._hoteldata.restore();

    // this._testdata.testRestore();
    this.totalScore();
    this.limit_score = this._hoteldata.currentConfig.data.limit_score;
    this.playing_score = this._hoteldata.currentConfig.data.playing_score;
    this.visita = this._hoteldata.currentConfig.data.test.visita;


    if(loadSections){
      // Genera llaves y nombres desde secciones en currentConfig.data
      this.secciones = this._hoteldata.currentConfig.data.secciones.map(clave => {
        const name: Sections = secciones.find(el => el.id === clave);
        return { nombre: name.name, clave: name.id, puntos: name.total_score };
      });
    }

    // Filtrar preguntas existentes
    let filteredQuestions = []
    questions.forEach(_tpregunta=>{
      let filtered = this.secciones.filter( seccion => seccion.clave == _tpregunta.seccion_clave );
      let existing = this._hoteldata.currentConfig.data.preguntas.filter(exist => String(exist[0]) === _tpregunta.id);

      if(existing && existing.length!==0) {
        _tpregunta.checked= existing[0][2] !== "undefined";
      }
      if(filtered && filtered.length!==0) filteredQuestions.push(_tpregunta);
    });
    this.preguntas  = filteredQuestions;
  }

  make_totalScore(){
    let total:number=0;
    this.secciones.forEach(seccion=>{
      total = total + seccion.puntos;
    });
    this.hotel_info.limit_score = total;
  }

  // Desencadenar eventos al elegir seccion en input
  setSection(disSection?:string){
    this.currentSection = disSection || this.nativeSelSection.nativeElement.value;
    if(disSection) {
      this.nativeSelSection.nativeElement.value = disSection;
      this.initTest();
    }

    let first = this.preguntas.find(question=>{
      return question.seccion_clave == this.currentSection;
    });
    this.matchKeySection(false);
    this.makePregunta(first);
    this.questions_desplacement = this.questionCircle;
  }

  async matchKeySection(OnInit:boolean){ // Ubicar Datos de sección actual
    let llave:string = this.currentSection; //Viene de input
    const secciones = await this._hoteldata.secciones$.toPromise();

    if(OnInit) llave = this.secciones[0].clave;

    secciones.forEach( section => {
      if ( section.id == llave ) this.currentSectionData = section;
    });
  }

  //Renderear pregunta y esparcir data
  makePregunta(selectedPregunta: Question, index?: number) {
    this.extraInput = '';
    if (!this.isOpenReactions) this.toggleReactions();

    this.updateCurrentPreguntaData(selectedPregunta);

    const savedQuestion = this._hoteldata.currentConfig.data.preguntas.find(pregunta => String(pregunta[0]) === selectedPregunta.id);

    if (savedQuestion) {
      this.loadSavedQuestionData(savedQuestion);
    }

    this.reaction(this.currentPreguntaData.marca);

    const editedIndex = this.preguntas.indexOf(selectedPregunta);
    this.currentIndex = index !== undefined ? Number(index) + 1 : Number(editedIndex) + 1;

    this.deslizarCentro(selectedPregunta.id);

    setTimeout(() => {
      this.overflowed = this.nativeLiteralquestionbox.nativeElement.scrollHeight > 96;
    }, 350);

    this.questionScrolled();
    this.autosave();
  }

  private updateCurrentPreguntaData(selectedPregunta: Question) {
    this.currentPreguntaData = {
      id: selectedPregunta.id,
      pregunta: selectedPregunta.pregunta,
      checked: selectedPregunta.checked,
      marca: selectedPregunta.marca,
      capex: selectedPregunta.capex,
      valor_section: selectedPregunta.valor_section,
      notas: selectedPregunta.notas,
      fotos: selectedPregunta.fotos ?? [],
      seccion_clave: selectedPregunta.seccion_clave, // Added property
      respuesta: selectedPregunta.respuesta,         // Added property
      hora: selectedPregunta.hora,                   // Added property
      fecha: selectedPregunta.fecha,                 // Added property
      valor: selectedPregunta.valor,                 // Added property
      title_input_extra: selectedPregunta.title_input_extra, // Added property
      input_extra: selectedPregunta.input_extra,     // Added property
      valor_subsection: selectedPregunta.valor_subsection, // Added property
      subsection: selectedPregunta.subsection        // Added property
    };
  }

  private loadSavedQuestionData(savedQuestion: any) {
    this.currentPreguntaData = {
      ...this.currentPreguntaData,
      marca: savedQuestion[3],
      respuesta: savedQuestion[2],
      capex: savedQuestion[6],
      notas: savedQuestion[7],
      fotos: savedQuestion[8],
      seccion_clave: savedQuestion[9], // Ensure this property is included if relevant
      hora: savedQuestion[10],         // Ensure this property is included if relevant
      fecha: savedQuestion[11]         // Ensure this property is included if relevant
    };

    this.extraInput = savedQuestion[10] ?? '';
  }

  @ViewChild("questionType") nativeQuestion : ElementRef;

  get Question(){
    return this.clearType(this.nativeQuestion.nativeElement.value);
  }

  set Question(value){
    this.nativeQuestion.nativeElement.value = value;
  }

  filterPreguntas:Array<any>=[];
  showFilteredQuestions:boolean = false;
  showQuestionSearch:boolean = false;

  toogleQuestionSearch(){
    this.showQuestionSearch = !this.showQuestionSearch;
    if(!this.showQuestionSearch) return;
    // Jarvis.say("En esta ventana podrás buscar preguntas por palabras clave",{ lang:"es-ES" });
    setTimeout(() => {
      this.nativeQuestion.nativeElement.focus();
    }, 200);
    this.choosenFilter="";
  }

  textoGrabado:string="";
  statusGrabacion:string ="";

  clearType(texto:string){
    texto = texto.replace(/á/gi,"a");
    texto = texto.replace(/é/gi,"e");
    texto = texto.replace(/í/gi,"i");
    texto = texto.replace(/ó/gi,"o");
    texto = texto.replace(/ú/gi,"u");
    texto = texto.toLowerCase();
    return texto;
  }

  choosenFilter:string = "";

  async filterBy(selection: string) {
    const questions = await this._testdata.questions$.toPromise();
    this.choosenFilter = this.choosenFilter == selection && selection !== "" ? "" : selection;
    if (this.choosenFilter !== "") {
      this.filterPreguntas = [];
      questions.forEach((pregunta: Question) => {
        this.preguntaSearcher(pregunta);
      });
      this.showFilteredQuestions = true;
    }
  }

  async key_preguntas() {
    const questions = await this._testdata.questions$.toPromise();
    this.filterPreguntas = [];
    if (this.Question.length < 2) this.showFilteredQuestions = false;
    else this.showFilteredQuestions = true;

    questions.forEach((pregunta: Question) => {
      let question: string = this.clearType(pregunta.pregunta);
      if (question.indexOf(this.Question) !== -1 && this._hoteldata.currentConfig.data.secciones.indexOf(pregunta.seccion_clave) !== -1) {
        this.preguntaSearcher(pregunta);
      }
    });
  }

  preguntaSearcher(pregunta){
    this._hoteldata.restore()
    // let savedTest:storedTest = this._testdata.savedTest.find(stest=>{return stest.id == this._hoteldata.currentConfig.data.test.id});
    let existentP;
    existentP = this._hoteldata.currentConfig.preguntas.find( ep=>{ return ep[0] == pregunta.id });
    if(existentP!==undefined) pregunta.respuesta = existentP[2];
    else pregunta.respuesta = "--"

    let filterResponse: boolean | string;
    switch (this.choosenFilter) {
      case 'close':
        filterResponse = false;
      break;
      case 'checked':
        filterResponse = true;
      break;
      case 'forbidden':
        filterResponse = "discard";
      break;
      default:
      filterResponse = "sinFiltro"
      break;
    }

    if(pregunta.respuesta == filterResponse)  this.filterPreguntas.push(pregunta);
    if(filterResponse == "sinFiltro") this.filterPreguntas.push(pregunta);
  }

  flyTo(preguntaClave:string){
    this._hoteldata.currentConfig.lastVisit = preguntaClave;
    this.loadLastSection();
    this.showQuestionSearch = false;
    this.showFilteredQuestions = false;
  }

  async translateClave(clave){
    const secciones = await this._hoteldata.secciones$.toPromise();
    let secData = secciones.find( seccion => { return seccion.id == clave });
    if(secData) return secData.name;
  }

  async totalScore() {
    let suma: number = 0;
    const questions = await this._testdata.questions$.toPromise();

    this._hoteldata.currentConfig.data.preguntas.forEach(pregunta => {
      let fullPregunta = questions.find(fp => { return fp.id.toString() === pregunta[0].toString() });
      if (pregunta[2] && pregunta[2] !== "discard") suma += fullPregunta.valor_section;
    });

    this._hoteldata.currentConfig.playing_score = suma;
    let wchTest = this._testdata.Cuestionarios.find(tst => { return tst.id == this._hoteldata.currentConfig.data.test.id });
    let indexTest = this._testdata.Cuestionarios.indexOf(wchTest);

    if (indexTest !== -1) this._testdata.Cuestionarios[indexTest].playing_score = suma;
    // this._testdata.testChanges();
  }

  newComment(){
    this.editingComment=false;
    let textarea:string = this.capitalize(this.nativeCommentInterface.nativeElement.value);
    if(textarea.length!==0)this.currentPreguntaData.notas.push(textarea);

    this.toggleCommentWindow();
    this._hoteldata.currentConfig.test.saved=false;
    this.storeWithoutAnswering();
  }

  deleteComment(comment:string){
    let index = this.currentPreguntaData.notas.indexOf(comment);
    this.currentPreguntaData.notas.splice(index,1);
    this._hoteldata.currentConfig.test.saved=false;
    this.storeWithoutAnswering();
  }

  deslizarCentro(PreguntaID) {
    //Desplazar contenedor de preguntas(.spacer)
    let currentSectionArray = [];
    this.preguntas.forEach(edSec => {
      if (edSec.seccion_clave == this.currentSection) currentSectionArray.push(edSec.id);
    });
    let posQuest = currentSectionArray.indexOf(PreguntaID);

    if (!posQuest) posQuest = 0;
    this.questions_desplacement = this.questionCircle * (posQuest + 1);
  }

  deleteAllQuestions(){
    this._hoteldata.currentConfig.preguntas = [];
    this._hoteldata.restore();
  }

  addCapex(){
    this.currentPreguntaData.capex = !this.currentPreguntaData.capex;
    this.storeWithoutAnswering();
  }

  @ViewChild("imgMaster", { static: true }) nativeImgMaster : ElementRef;
  imgSelection:string="unselected";

  toggleShowImg(imgName:string){
    this.imgVisor = !this.imgVisor;
    if(this.imgVisor && this.imgSelection == "unselected") {
      this.imgSelection = imgName;
      window.scrollTo(0,0)
    }
    else this.imgSelection = "unselected";
  }

  editingComment:boolean=false;
  toggleCommentWindow(comment?:string){
    this.comment_window = !this.comment_window;

    if(comment){
      this.editingComment=true;
      this.deleteComment(comment);
      this.nativeCommentInterface.nativeElement.value = comment;
    }else{
      if(!this.comment_window && this.microphone) this.stop();
      setTimeout(() => {
        this.nativeCommentInterface.nativeElement.value="";
      }, 380);
    }

  }

  toggleReactions(){
    let currentPos:number = this.reacPos;
    if(this.isOpenReactions) this.reacPos = 0;
    else this.reacPos = currentPos;
    this.isOpenReactions = !this.isOpenReactions;
  }

  reaction(selection:string|number,Save?:boolean){
    switch (selection) {
      case 'cancel':
      case 0:
      this.reacPos = 0;
      this.currentPreguntaData.marca=0;
      this.reacColor = "#818181";
      break;
      // case 'clock':
      // case 1:
      // this.reacPos = -49;
      // this.currentPreguntaData.marca=1;
      // this.reacColor = "#c5d9ad";
      // break;
      case 'warn':
      case 2:
      this.reacPos = -49;
      this.currentPreguntaData.marca=2;
      this.reacColor = "#dea945";
      break;
      // case 'seen':
      // case 3:
      // this.reacPos = -160;
      // this.currentPreguntaData.marca=3;
      // this.reacColor = "#e06d2a";
      // break;
      case 'deathly':
      case 4:
      this.reacPos = -106;
      this.currentPreguntaData.marca=4;
      this.reacColor = "#2a1212";
      break;
      default:
      this.reacPos = 0;
      this.currentPreguntaData.marca=0;
      this.reacColor = "#818181";
      break;
    }
    this._hoteldata.currentConfig.test.saved=false;
    if(Save)this.storeWithoutAnswering();
  }

  storeWithoutAnswering(){
    let doesItExist = this._hoteldata.currentConfig.preguntas.find(x=>x[0]==this.currentPreguntaData.id);

    if(doesItExist==undefined){
      let unaswered: Answer = [
        this.currentPreguntaData.id,
        this._hoteldata.currentConfig.test.id,
        false,
        this.currentPreguntaData.marca,
        moment().format('DD[/]MM[/]YYYY'),
        moment().format('h:mm:ss a'),
        this.currentPreguntaData.capex,
        this.currentPreguntaData.notas,
        this.currentPreguntaData.fotos,
        0,
        this.currentPreguntaData.input_extra ? this.extraInput : null,
      ];

      this._hoteldata.currentConfig.data.preguntas.push(unaswered)

      this._hoteldata.forceUpload.next(this._hoteldata.currentConfig.data);
      this.initTest();
    }else{
      if(this.currentPreguntaData.respuesta=="discard"){
        this.answerTheQuestion(false,"discard",true);
      }
      this.answerTheQuestion(this.currentPreguntaData.respuesta,"",true);
    }

    if(this.ConnectionStatus)this.syncData(false);
  }

  abstracAnswer(calification:boolean,qClave:string){
    let configQ:Array<any> = this._hoteldata.currentConfig.data.preguntas;
    let disQuestion = configQ.find(x=>{ return x[0] == qClave });
    let Qindex:number = configQ.indexOf(disQuestion);

    if(Qindex!== -1) {
      this._hoteldata.currentConfig.preguntas[Qindex][2] = calification;
      // console.log(`Pregunta ${qClave} changed to ${calification}`,this._hoteldata.currentConfig.preguntas[Qindex]);
      this.totalScore();
      this._hoteldata.forceUpload.next(this._hoteldata.currentConfig.data);
      if(this.ConnectionStatus)this.syncData(false);
    }

  }

  async answerTheQuestion(sel?: boolean | string, discard?: string, justSave?: boolean) {
    if (discard === "discard") {
        this.hotel_info.limit_score -= Number(this.currentPreguntaData.valor_section);
        this._hoteldata.currentConfig.limit_score = this.hotel_info.limit_score;
    }

    const actualQuestion: Answer = [
      this.currentPreguntaData.id,
      this._hoteldata.currentConfig.data.test.id,
      discard === "discard" ? discard : sel,
      this.currentPreguntaData.marca,
      moment().format('DD[/]MM[/]YYYY'),
      moment().format('h:mm:ss a'),
      this.currentPreguntaData.capex,
      this.currentPreguntaData.notas,
      this.currentPreguntaData.fotos,
      this.preguntas.find(x => x.id === this.currentPreguntaData.id)?.valor_section,
      this.currentPreguntaData.input_extra ? this.extraInput : null,
    ];

    const configQ = this._hoteldata.currentConfig.data.preguntas;
    const c_question = configQ.find(existing => String(existing[0]) === this.currentPreguntaData.id);

    if (c_question) {
        const index = configQ.indexOf(c_question);
        this._hoteldata.currentConfig.preguntas[index] = actualQuestion;
    } else {
        this._hoteldata.currentConfig.data.preguntas.push(actualQuestion);
    }

    this.totalScore();
    this._hoteldata.currentConfig.test.saved = false;

    if (justSave) return;

    this.initTest();
    this.nextStep();

    if (this.saveCount) return;

    this.saveCount = true;
    this.waitForIt = setTimeout(() => {
        if (this.ConnectionStatus) this.syncData();
    }, 1000);
}

  nextStep(){
    // Actual settings
    let preguntaActual = this.currentPreguntaData.id;

    // Ubicar siguiente pregunta
    let pregunta=this.preguntas.find(pregunta => { return pregunta.id == preguntaActual});
    let index = this.preguntas.indexOf(pregunta);
    let nextPregunta= this.preguntas[(index)+1];

    if(!nextPregunta || nextPregunta.seccion_clave!==pregunta.seccion_clave ){// Detectar si es la última pregunta de la sección => cambiar a siguiente sección
      //Obtener nueva seccion
      let secIndex:number = this._hoteldata.currentConfig.data.secciones.indexOf(pregunta.seccion_clave);
      let nextSection:string =this._hoteldata.currentConfig.data.secciones[(secIndex)+1];

      if (nextSection){// Detectar si es la última preginta del test => send to hotelSearch o mensaje de terminado
        this.currentSection = nextSection;
        this.setSection();
      }else{
        console.log("Final de evaluación");
      }
    }else{
      // Avanzar a la siguiente pregunta
      this.makePregunta(nextPregunta);
    }

  }

  toogleMenu(){
    this.MenuOn = !this.MenuOn;
  }

  navScroll($event){
    if ($event.target.scrollLeft <= 10) this.navReached = true;
    else this.navReached = false;
  };

  autosave(){ // Recordar última visita
    this._hoteldata.currentConfig.lastVisit=this.currentPreguntaData.id;
    // this._testdata.strdData();
  }

  loadLastSection(){
    let lastQuestion:string = this._hoteldata.currentConfig.data.lastVisit;
    let lastSection:string = lastQuestion.slice(-5);
    let formattedQuestion = this.preguntas.find( el => { return el.id == lastQuestion });

    if(lastQuestion!==""){
      setTimeout(() => {
        this.currentSection = lastSection;
        this.matchKeySection(false);
        this.makePregunta(formattedQuestion);
        this.deslizarCentro(lastQuestion)
      },300);
    }else{
      this.matchKeySection(true);
    }
  }

  makeFromReport(pregunta,seccion){
    console.log("pregunta",pregunta);
    console.log("seccion",seccion);

    this.currentSection = seccion;
    this.setSection();
    this.makePregunta(pregunta);
  }

  ///////////////////// Lector de imágenes /////////////////////

  @ViewChild("fotosInput", { static: true }) nativeFotosInput:ElementRef;
  _fileList:Array<file>;

  leerImagenes($event){
    this._fileList = $event.target.files;
    let errors = "";

    if(!this._fileList) errors+= "Archivo no soportado; admitidos: JPG y PNG.";
    if(this._fileList && this._fileList[0]){

      for (let i = 0; i < this._fileList.length; i++) {
        let _file = this._fileList[i];
        if ( (/\.(jpeg|jpg|png)$/i).test(_file.name) ) {
          if(Math.round(_file.size/1024) < 7500){
              if(this.ConnectionStatus) this.loading=true;
              this.readFile( _file , _file.name);
          }else errors += "El tamaño de la imagen debe ser menor a 4.5MB";
        } else errors += _file.name +", extensión de archivo no soportada\n";
      }
    }
    if (errors){
      this._authService._notifications.warn(
        "Precaución",
        errors,
        this._authService.settings
      )
      console.log(errors);
    }
  }

  imgEntry:foto;

  readFile(file,filename:string){
    let reader = new FileReader();
    this.imgEntry=undefined;

    let imageObj = window.URL.createObjectURL(file);

    reader.addEventListener("load",a=>{
        daycaca.resize(imageObj, !this.ConnectionStatus? 0.2 : 0.25, data=>{
          daycaca.compress( data, !this.ConnectionStatus? 29 : 35, subdata=>{
            if(this.currentPreguntaData.fotos==null) this.currentPreguntaData.fotos = [];
              this.imgEntry = {name:filename,url:subdata,id:null};
              this.loadImage(filename,subdata);
              this.currentPreguntaData.fotos.push(this.imgEntry);
          });
        });
    });

    reader.readAsDataURL(file);
    this._hoteldata.currentConfig.test.saved=false;

  }

  loadImage(name:string,data:string){
    const imgData:loadPhoto = {
      hotel_clave:this._hoteldata.currentConfig.data.hotel.clave.toString(),
      cuestionario_clave:this._hoteldata.currentConfig.data.test.id.toString(),
      pregunta_clave:this._hoteldata.currentConfig.data.lastVisit.toString(),
      name:name,
      base64:data
    }

    //console.log("imgData",imgData);

    if(!this.ConnectionStatus){
      this.storeWithoutAnswering();
      console.log("test offline: ",this.ConnectionStatus);
      this.laggedImages.push(imgData);
      return;
    }

    this._evalService.uploadPhoto(imgData)
    .pipe(
      pluck("data_photo"),
      tap((x:any)=>{
        this.changeBase64ToURL(x.url,name,x.id);
        this.imgEntry.id=x.id;
      })
    )
    .subscribe(
      res=>{
        console.log("test online: ",this.ConnectionStatus);
        this.storeWithoutAnswering();
      },
      err=>{
        console.log("error",err);
      },
      ()=>{
        console.log("image loaded");
        this.loading=false;
      }
    )
  }

  loadLaggedImages(){
    console.log("this.laggedImages",this.laggedImages);
    if(this.laggedImages.length > 0){
      this.laggedImages.forEach((elimg:loadPhoto) => {
        this.loadImage(elimg.name,elimg.base64);
        console.log(`${elimg.name} lagged image`);
        let subindex = this.laggedImages.indexOf(elimg);
        this.laggedImages.splice(subindex,1);
      });
    }else console.log("Nothing lagged to load");
  }

  changeBase64ToURL(newURL:string,filename:string,id:number){
    const nwImg = {
      name:filename,
      url:`${environment.Protocol}://${environment.Host}/${newURL}`,
      id:id
    }

    let oldImg = this.currentPreguntaData.fotos.find((x:any)=>{return x.name == filename})
    let index = this.currentPreguntaData.fotos.indexOf(oldImg);

    this.currentPreguntaData.fotos[index] = nwImg;
  }

  cerrarImg(imgName:string,id:number){
    console.log("id",id);

    if(!this.ConnectionStatus){
      this.removeImagesLS(imgName)
      return;
    }

    this._evalService.removePhoto(id)
    .subscribe(
      res=>console.log(Object.keys(res)[0]),
      err=>{
        if(this.ConnectionStatus)console.log('Hubo un error: ',err)
      },
      ()=> this.removeImagesLS(imgName)
    )
  }

  removeImagesLS(imgName:string){

    if(this.laggedImages.length > 0){
      let subindex = this.laggedImages.indexOf(imgName);
      this.laggedImages.splice(subindex,1);
    }

    let ind = this.currentPreguntaData.fotos.find(pdata => {
      return pdata.name == imgName;
    });
    let index = this.currentPreguntaData.fotos.indexOf(ind);
    this.currentPreguntaData.fotos.splice(index,1);
    this._hoteldata.currentConfig.test.saved=false;

    this.answerTheQuestion(this.currentPreguntaData.respuesta,"",true);
  }

  toggleSummary(value?:boolean){
    if(value||!this.summary){
      this.summary=value||!this.summary;
      this.MenuOn = false;
      setTimeout(() => {
        this.showComponent= true;
      }, 500);
    }else{
      this.showComponent= false;
      setTimeout(() => {
        this.summary=value||!this.summary;
        this.MenuOn = false;
      }, 500);
    }
  }

    async ngOnInit() {
      this.preguntas = await this._testdata.questions$.toPromise();
      const secciones = await this._hoteldata.secciones$.toPromise();

      this.brenchSection = this.preguntas.filter(pregunta => pregunta.seccion_clave === this.secciones[0].clave);

      if (this._hoteldata.currentConfig.data.lastVisit !== "") {
          this.matchKeySection(true);
          this.loadLastSection();
      } else {
          this.makePregunta(this.brenchSection[0], 0);
          this.matchKeySection(true);
          this.questions_desplacement = this.questionCircle;
      }

      fromEvent(document, "visibilitychange").subscribe(() => {
          this._hoteldata.restore();
          const saved = this._hoteldata.currentConfig.data.test.saved;
          this.visibility = document.hidden;
          if (this.visibility && !saved) {
              this.syncData();
              this._notifications.info(
                  "Nota", // title
                  "Guardado automático por inactividad", // content
                  this._authService.settings // override
              );
          }
      });
  }

  isSelectType(textType: string): boolean {
    return textType.toLowerCase().includes("select");
  }

  getInputType(textType: string): string | string[] {
    try {
      // if textType contains select
      if (this.isSelectType(textType)) {
        // then the textType is in this format select[option,option,option]
        // get options
        const options = textType.split("[")[1].split("]")[0].split(",");
        return options;
      }
    } catch (error) {
      // Handle any errors that occur during parsing
      console.info("Error parsing", textType);
      console.error("Error parsing select options:", error);
    }

    // else textType is a string
    switch (textType) {
      case "input_entero":
        return "number"; // integer type
      case "input_entero_decimales":
        return "number"; // decimal type
      case "input_month_year":
        return "month"; // month type
      case "input_year":
        return "number"; // year type
      case "input_string":
        return "text"; // string type
      default:
        return "text";
    }
  }

}
