import { Subject } from 'rxjs';
import { foto } from './testdata.service';

export type Answer = [
  string, // id
  number | string, // test id
  string | boolean, // discard
  number, // marca
  string, // date
  string, // time
  boolean, // capex
  string[], // notas
  foto[], // fotos
  number | undefined, // valor_section
  string | null // extra_input
];

export interface data{
  user:data_user,
  playing_score?:number,
  limit_score?:number,
  secciones?:Array<string>,
  hotel?:data_hotel,
  test?:data_test,
  preguntas?: Array<Answer>,
  lastVisit?:string
}

export interface data_hotel{
  id?:number,
  name?:string,
  clave?:string,
  edad?:number,
  gerente?:string,
  habitaciones?:number,
  logo?:string,
  segmento?:string
}

export interface data_user{
  user_id?:number,
  user_name?:string,
  user_mail?:string,
  user_eval_count?:number,
  user_avatar?:string,
  user_cargo?:string,
}

export interface data_test{
  id?:number|string,
  clave?:any,
  saved?:boolean,
  visita?:number,
  status?:string,
}

export class CurrentConfig {

  data:data={
    user:{
      user_id:0,
      user_name:"",
      user_mail:"",
      user_eval_count:0,
      user_avatar:"",
      user_cargo:"",
    },
    limit_score:0,
    playing_score:0,
    secciones:[],
    hotel:{
      id:0,
      name:"",
      clave:"",
      edad:0,
      gerente:"",
      habitaciones:0,
      logo:"",
      segmento:""
    },
    test:{
      id:0,
      clave:"",
      saved:false,
      visita:0,
      status:"activo"
    },
    preguntas:[],
    lastVisit:""
  }

  changes:Subject<any> = new Subject<any>();

  get user(): data_user {
    return this.data.user;
  }
  set user(value: data_user) {
    this.data.user = value;
    this.changes.next(this.data);
  }

  get playing_score(): number | undefined {
    return this.data.playing_score;
  }
  set playing_score(value: number | undefined) {
    this.data.playing_score = value;
    this.changes.next(this.data);
  }

  get limit_score(): number | undefined {
    return this.data.limit_score;
  }
  set limit_score(value: number | undefined) {
    this.data.limit_score = value;
    this.changes.next(this.data);
  }

  get secciones(): Array<string> | undefined {
    return this.data.secciones;
  }
  set secciones(value: Array<string> | undefined) {
    this.data.secciones = value;
    this.changes.next(this.data);
  }

  get hotel(): data_hotel | undefined {
    return this.data.hotel;
  }
  set hotel(value: data_hotel | undefined) {
    this.data.hotel = value;
    this.changes.next(this.data);
  }

  get test(): data_test | undefined {
    return this.data.test;
  }
  set test(value: data_test | undefined) {
    this.data.test = value;
    this.changes.next(this.data);
  }

  get preguntas(): Array<any> | undefined {
    return this.data.preguntas;
  }
  set preguntas(value: Array<any> | undefined) {
    this.data.preguntas = value;
    this.changes.next(this.data);
  }

  get lastVisit(): string | undefined {
    return this.data.lastVisit;
  }
  set lastVisit(value: string | undefined) {
    this.data.lastVisit = value;
    this.changes.next(this.data);
  }

}
