import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FootheadService } from '../../services/foothead.service'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {

  title:string = "";

  constructor(
    _router:Router,
    _foothead : FootheadService,
  ) {
    _router.events.subscribe((val) => {
      let direction = _router.url;
      this.title = _foothead.selectTitle(direction);
    });
  }



  ngOnInit() {
  }

}
