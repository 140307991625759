
<h3 [routerLink]="['']" style="color:orangered;border:.1rem solid orangered;display: inline-block;padding:.5rem;"><i class="icon icon-arrow-l"></i> Volver</h3>

<article>
  <i class="icon icon-sync"></i>
  <i class="icon icon-candado-open"></i>
  <i class="icon icon-candado-closed"></i>
  <i class="icon icon-important"></i>
  <i class="icon icon-edit"></i>
  <i class="icon icon-close"></i>
  <i class="icon icon-checked"></i>
  <i class="icon icon-arrow-r"></i>
  <i class="icon icon-arrow-l"></i>
  <i class="icon icon-eyes"></i>
  <i class="icon icon-picture"></i>
  <i class="icon icon-trash"></i>
  <i class="icon icon-credential"></i>
  <i class="icon icon-mail"></i>
  <i class="icon icon-question"></i>
  <i class="icon icon-shield"></i>
  <i class="icon icon-four-squares"></i>
  <i class="icon icon-clock"></i>
  <i class="icon icon-voice"></i>
  <i class="icon icon-search"></i>
  <i class="icon icon-powerOff"></i>
  <i class="icon icon-circle"></i>
  <i class="icon icon-hand-up"></i>
  <i class="icon icon-arrow-down"></i>
  <i class="icon icon-plus"></i>
  <i class="icon icon-arrow-up"></i>
  <i class="icon icon-frobidden"></i>
</article>

<article class="fields">
    <div class="fields__user ">
      <input type="text" name="user" placeholder="Usuario">
      <i class="icon icon-arrow-r"></i>
    </div>
  </article>

<!-- Generator: Adobe Illustrator 17.0.0, SVG Export Plug-In  -->
<svg version="1.1"
	 xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/"
	 x="0px" y="0px" width="234.678px" height="181.398px" viewBox="0 0 234.678 181.398" enable-background="new 0 0 234.678 181.398"
	 xml:space="preserve">
<defs>
</defs>
<g>
	<ellipse fill="#A0CACA" cx="117.339" cy="133.867" rx="117.339" ry="47.531"/>
	<g>
		<rect x="79.181" y="31.753" fill="#FFCE33" width="107.561" height="106.41"/>
		<rect x="42.369" y="4.722" fill="#FBA013" width="107.561" height="106.41"/>
		<polyline fill="#DD8114" points="42.369,111.132 42.369,4.722 149.93,4.722 		"/>
		<g>
			<path d="M155.143,89.398v27.027H38.718V0h116.426V89.398z M47.628,8.91v71.578h6.238l32.076-53.461l20.196,34.749l10.097-10.989
				l23.167,29.7h6.533V8.91H47.628z M47.628,107.218h98.308v-17.82H47.628V107.218z M128.115,80.488L115.642,64.45l-11.287,12.177
				L85.644,44.551L64.26,80.488H128.115z M190.784,26.73h-26.73v8.91h17.82v98.605H83.566v-8.91h-9.208v17.82h116.426V26.73z"/>
		</g>
	</g>
</g>
</svg>

<!-- Generator: Adobe Illustrator 17.0.0, SVG Export Plug-In  -->
<svg version="1.1"
	 xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/"
	 x="0px" y="0px" width="421.903px" height="196.766px" viewBox="0 0 421.903 196.766" enable-background="new 0 0 421.903 196.766"
	 xml:space="preserve">
<defs>
</defs>
<g>
	<ellipse fill="#6B7D7D" cx="210.951" cy="98.383" rx="210.951" ry="98.383"/>
	<g>
		<g>
			<g>
				<path fill="#F2F2F2" d="M181.15,98.408l-1.763,0.026l-12.21-26.842l2.222-0.033l10.873,24.523l10.169-24.84l2.108-0.032
					L181.15,98.408z"/>
				<path fill="#F2F2F2" d="M208.938,97.798l-0.049-3.258c-1.313,1.898-3.701,3.774-7.418,3.83c-3.641,0.055-7.58-1.956-7.642-6.019
					l-0.001-0.076c-0.062-4.063,3.391-6.415,8.451-6.491c2.72-0.041,4.604,0.275,6.489,0.746l-0.013-0.844
					c-0.06-3.947-2.505-5.942-6.528-5.882c-2.377,0.036-4.321,0.717-6.109,1.664l-0.715-1.638c2.131-1.029,4.229-1.75,6.912-1.79
					c5.021-0.076,8.203,2.482,8.279,7.542l0.183,12.188L208.938,97.798z M208.832,88.255c-1.654-0.397-3.846-0.824-6.643-0.782
					c-4.14,0.063-6.412,1.937-6.371,4.657l0.001,0.076c0.043,2.837,2.75,4.444,5.74,4.399c3.985-0.061,7.36-2.564,7.307-6.128
					L208.832,88.255z"/>
				<path fill="#F2F2F2" d="M226.156,98c-5.633,0.085-10.036-4.525-10.118-10.006l-0.001-0.077c-0.083-5.48,4.216-10.3,9.812-10.384
					c3.68-0.055,6.003,1.598,7.872,3.486l-1.32,1.398c-1.713-1.698-3.651-3.164-6.564-3.12c-4.445,0.066-7.838,3.835-7.768,8.512
					l0.001,0.076c0.07,4.714,3.69,8.416,8.175,8.349c2.76-0.041,4.885-1.491,6.505-3.356l1.321,1.131
					C232.111,96.3,229.76,97.946,226.156,98z"/>
				<path fill="#F2F2F2" d="M239.681,97.337l-0.294-19.546l1.878-0.028l0.294,19.546L239.681,97.337z M239.529,74.529l3.297-5.147
					l2.354,1.114l-4.041,4.009L239.529,74.529z"/>
				<path fill="#F2F2F2" d="M257.625,97.526c-5.787,0.088-10.072-4.524-10.154-10.005l-0.001-0.077
					c-0.083-5.48,4.099-10.298,9.924-10.386c5.788-0.087,10.073,4.525,10.156,10.007l0.001,0.076
					C267.633,92.622,263.451,97.439,257.625,97.526z M265.521,87.171c-0.071-4.715-3.654-8.416-8.177-8.349
					c-4.638,0.07-7.914,3.914-7.845,8.514l0.001,0.076c0.07,4.714,3.653,8.417,8.175,8.349c4.639-0.069,7.916-3.914,7.847-8.512
					L265.521,87.171z"/>
			</g>
			<g>
				<path fill="#F2F2F2" d="M176.618,121.087l-8.546-10.781v10.781h-0.866v-12.134h0.85l8.372,10.557v-10.557h0.867v12.134H176.618z
					"/>
				<path fill="#F2F2F2" d="M184.62,121.295c-2.617,0-4.524-2.114-4.524-4.594v-0.034c0-2.479,1.925-4.629,4.56-4.629
					c2.617,0,4.524,2.115,4.524,4.594v0.035C189.18,119.146,187.255,121.295,184.62,121.295z M188.261,116.668
					c0-2.133-1.595-3.831-3.641-3.831c-2.097,0-3.605,1.716-3.605,3.796v0.035c0,2.132,1.595,3.83,3.641,3.83
					c2.097,0,3.605-1.716,3.605-3.796V116.668z"/>
				<path fill="#F2F2F2" d="M203.716,121.087v-5.252c0-1.82-1.022-3.017-2.809-3.017c-1.733,0-3.067,1.317-3.067,3.155v5.113h-0.85
					v-12.654h0.85v5.461c0.589-1.04,1.577-1.855,3.189-1.855c2.235,0,3.535,1.525,3.535,3.641v5.408H203.716z"/>
				<path fill="#F2F2F2" d="M213.677,121.087v-1.474c-0.606,0.85-1.698,1.682-3.38,1.682c-1.646,0-3.415-0.937-3.415-2.773v-0.034
					c0-1.838,1.577-2.878,3.866-2.878c1.23,0,2.08,0.156,2.929,0.382v-0.382c0-1.785-1.092-2.704-2.912-2.704
					c-1.074,0-1.958,0.295-2.773,0.711l-0.312-0.746c0.971-0.45,1.924-0.762,3.137-0.762c2.271,0,3.692,1.178,3.692,3.467v5.512
					H213.677z M213.695,116.771c-0.745-0.19-1.733-0.398-2.999-0.398c-1.872,0-2.912,0.832-2.912,2.063v0.035
					c0,1.282,1.214,2.027,2.565,2.027c1.803,0,3.346-1.109,3.346-2.721V116.771z"/>
				<path fill="#F2F2F2" d="M221.232,121.4c-0.78,1.854-1.682,2.513-2.947,2.513c-0.676,0-1.161-0.121-1.751-0.381l0.295-0.745
					c0.469,0.225,0.85,0.329,1.508,0.329c0.919,0,1.525-0.538,2.185-2.08l-4.247-8.788h0.988l3.675,7.904l3.189-7.904h0.937
					L221.232,121.4z"/>
				<path fill="#F2F2F2" d="M236.494,121.295c-2.548,0-4.506-2.114-4.506-4.594v-0.034c0-2.479,1.976-4.629,4.506-4.629
					c1.664,0,2.705,0.764,3.537,1.63l-0.607,0.624c-0.763-0.78-1.629-1.456-2.946-1.456c-2.011,0-3.571,1.682-3.571,3.796v0.035
					c0,2.132,1.612,3.83,3.641,3.83c1.248,0,2.219-0.641,2.964-1.473l0.589,0.52C239.199,120.568,238.124,121.295,236.494,121.295z"
					/>
				<path fill="#F2F2F2" d="M246.146,121.295c-2.617,0-4.524-2.114-4.524-4.594v-0.034c0-2.479,1.925-4.629,4.56-4.629
					c2.617,0,4.524,2.115,4.524,4.594v0.035C250.705,119.146,248.78,121.295,246.146,121.295z M249.786,116.668
					c0-2.133-1.595-3.831-3.641-3.831c-2.097,0-3.605,1.716-3.605,3.796v0.035c0,2.132,1.595,3.83,3.641,3.83
					c2.097,0,3.605-1.716,3.605-3.796V116.668z"/>
				<path fill="#F2F2F2" d="M265.832,121.087v-5.252c0-1.907-0.988-3.017-2.617-3.017c-1.49,0-2.791,1.145-2.791,3.12v5.148h-0.85
					v-5.305c0-1.837-1.005-2.964-2.582-2.964c-1.578,0-2.826,1.387-2.826,3.173v5.096h-0.849v-8.84h0.849v1.577
					c0.572-0.919,1.37-1.786,2.947-1.786c1.543,0,2.496,0.885,2.981,1.907c0.572-0.988,1.525-1.907,3.189-1.907
					c2.098,0,3.397,1.474,3.397,3.692v5.356H265.832z"/>
				<path fill="#F2F2F2" d="M273.557,120.498c1.369,0,2.305-0.606,3.033-1.387l0.589,0.521c-0.901,0.971-1.958,1.664-3.657,1.664
					c-2.322,0-4.334-1.854-4.334-4.61v-0.035c0-2.565,1.803-4.611,4.195-4.611c2.479,0,4.057,2.012,4.057,4.629v0.034
					c0,0.122,0,0.174-0.018,0.313h-7.332C270.245,119.215,271.822,120.498,273.557,120.498z M276.521,116.252
					c-0.122-1.785-1.145-3.433-3.173-3.433c-1.75,0-3.103,1.474-3.258,3.433H276.521z"/>
				<path fill="#F2F2F2" d="M286.639,121.087v-5.252c0-1.82-1.023-3.017-2.809-3.017c-1.733,0-3.068,1.317-3.068,3.155v5.113h-0.849
					v-8.84h0.849v1.646c0.59-1.04,1.578-1.855,3.189-1.855c2.236,0,3.537,1.525,3.537,3.641v5.408H286.639z"/>
				<path fill="#F2F2F2" d="M293.481,121.244c-1.369,0-2.479-0.746-2.479-2.427v-5.79h-1.283v-0.779h1.283v-2.826h0.85v2.826h3.103
					v0.779h-3.103v5.703c0,1.266,0.745,1.716,1.768,1.716c0.434,0,0.798-0.087,1.301-0.312v0.797
					C294.469,121.139,294.035,121.244,293.481,121.244z"/>
				<path fill="#F2F2F2" d="M303.531,121.087v-1.474c-0.606,0.85-1.698,1.682-3.38,1.682c-1.646,0-3.415-0.937-3.415-2.773v-0.034
					c0-1.838,1.578-2.878,3.865-2.878c1.231,0,2.08,0.156,2.93,0.382v-0.382c0-1.785-1.092-2.704-2.912-2.704
					c-1.074,0-1.959,0.295-2.773,0.711l-0.312-0.746c0.971-0.45,1.924-0.762,3.138-0.762c2.271,0,3.691,1.178,3.691,3.467v5.512
					H303.531z M303.549,116.771c-0.745-0.19-1.733-0.398-2.999-0.398c-1.872,0-2.912,0.832-2.912,2.063v0.035
					c0,1.282,1.214,2.027,2.565,2.027c1.803,0,3.346-1.109,3.346-2.721V116.771z"/>
				<path fill="#F2F2F2" d="M312.089,113.045c-2.045,0-3.831,1.543-3.831,4.42v3.623h-0.849v-8.84h0.849v2.513
					c0.693-1.577,2.167-2.722,3.918-2.651v0.936H312.089z"/>
				<path fill="#F2F2F2" d="M314.358,109.942v-1.161h1.057v1.161H314.358z M314.461,121.087v-8.84h0.85v8.84H314.461z"/>
				<path fill="#F2F2F2" d="M322.572,121.295c-2.617,0-4.524-2.114-4.524-4.594v-0.034c0-2.479,1.925-4.629,4.56-4.629
					c2.617,0,4.524,2.115,4.524,4.594v0.035C327.131,119.146,325.206,121.295,322.572,121.295z M326.212,116.668
					c0-2.133-1.595-3.831-3.641-3.831c-2.097,0-3.605,1.716-3.605,3.796v0.035c0,2.132,1.595,3.83,3.641,3.83
					c2.097,0,3.605-1.716,3.605-3.796V116.668z"/>
				<path fill="#F2F2F2" d="M332.604,121.261c-1.283,0-2.688-0.521-3.623-1.283l0.502-0.676c0.971,0.745,2.046,1.161,3.173,1.161
					c1.196,0,2.132-0.659,2.132-1.682v-0.034c0-1.04-1.109-1.422-2.34-1.769c-1.439-0.416-3.033-0.849-3.033-2.427v-0.034
					c0-1.422,1.195-2.444,2.912-2.444c1.057,0,2.253,0.381,3.137,0.971l-0.45,0.711c-0.815-0.537-1.786-0.885-2.722-0.885
					c-1.213,0-2.011,0.659-2.011,1.543v0.035c0,0.988,1.196,1.352,2.462,1.716c1.421,0.398,2.912,0.919,2.912,2.479v0.035
					C335.655,120.255,334.302,121.261,332.604,121.261z"/>
			</g>
		</g>
		<g>
			<path fill="#E6E6E6" d="M119.11,147.344H92.522c-2.52,0-4.839-1.173-6.528-3.303c-1.716-2.164-2.662-5.205-2.662-8.564V91.311
				c0-6.433,2.077-10.019,3.391-11.643V68.47h39.259v11.251c1.331,1.505,2.855,4.728,2.855,11.59v44.969
				c0,3.984-1.211,6.99-3.6,8.934C122.874,147.136,120.165,147.344,119.11,147.344z M87.614,69.362V79.99l-0.104,0.124
				c-1.228,1.468-3.287,4.874-3.287,11.197v44.166c0,6.359,3.49,10.975,8.298,10.975h26.589c0.96,0,3.423-0.188,5.563-1.93
				c2.17-1.767,3.271-4.539,3.271-8.242V91.311c0-7.136-1.713-10.025-2.734-11.112l-0.121-0.129V69.362H87.614z"/>
			<path fill="#E6E6E6" d="M87.237,79.885L87.1,79.77c0.426-0.51,0.714-0.743,0.727-0.753l0.112,0.139
				C87.935,79.159,87.649,79.391,87.237,79.885z"/>
			<path fill="#E6E6E6" d="M127.878,131.421V87.703c-0.872-3.782-0.865-3.803-0.813-3.959l0.102-0.305l0.323-0.001
				c0.214,0,0.249,0,3.351,2.698c10.709,0.078,16.369,7.601,16.369,21.77c0,7.841-2.165,13.486-6.434,16.778
				c-3.479,2.682-7.413,2.972-8.942,2.972c-0.263,0-0.471-0.008-0.615-0.016L127.878,131.421z M128.184,85.026
				c0.174,0.78,0.404,1.787,0.575,2.526l0.011,0.049v41.462l2.079-2.353l0.23,0.025c0,0,0.28,0.029,0.755,0.029
				c3.398,0,14.484-1.36,14.484-18.858c0-8.405-2.001-14.366-5.947-17.717c-2.506-2.127-5.678-3.162-9.697-3.162h-0.167l-0.126-0.11
				C129.617,86.254,128.795,85.545,128.184,85.026z"/>
			<path fill="#E6E6E6" d="M132.427,122.19L132.427,122.19c-0.582,0-0.947-0.061-0.962-0.064l-0.119-0.02l-3.402-2.856V95.397
				l0.09-0.119c0.103-0.136,2.549-3.341,5.71-3.341c1.928,0,3.994,1.31,5.525,3.504c1.373,1.967,3.009,5.656,3.009,12.064
				c0,5.74-1.314,9.892-3.905,12.342C136.214,121.885,133.721,122.19,132.427,122.19z M131.727,121.261
				c0.133,0.015,0.376,0.036,0.699,0.036c1.161,0,3.399-0.273,5.332-2.101c2.406-2.275,3.626-6.209,3.626-11.693
				c0-10.691-4.687-14.675-7.641-14.675c-2.407,0-4.461,2.329-4.907,2.873v23.132L131.727,121.261z"/>
			<polygon fill="#E6E6E6" points="99.525,71.516 95.728,71.516 94.092,61.503 77.833,52.718 79.613,49.422 97.496,59.08 			"/>
			<polygon fill="#E6E6E6" points="100.13,75.223 96.332,75.223 96.017,73.301 99.815,73.301 			"/>
			<polygon fill="#E6E6E6" points="110.492,138.743 106.793,139.348 96.622,77.008 100.42,77.008 			"/>
			<g>
				<path fill="#E6E6E6" d="M126.596,77.455H86.802c-0.738,0-1.338-0.6-1.338-1.338s0.6-1.338,1.338-1.338h39.794
					c0.738,0,1.338,0.6,1.338,1.338S127.334,77.455,126.596,77.455z M86.802,75.671c-0.246,0-0.446,0.2-0.446,0.446
					s0.2,0.446,0.446,0.446h39.794c0.246,0,0.446-0.2,0.446-0.446s-0.2-0.446-0.446-0.446H86.802z"/>
			</g>
			<g>
				<path fill="#E6E6E6" d="M126.596,73.746H86.802c-0.738,0-1.338-0.6-1.338-1.338s0.6-1.338,1.338-1.338h39.794
					c0.738,0,1.338,0.6,1.338,1.338S127.334,73.746,126.596,73.746z M86.802,71.962c-0.246,0-0.446,0.2-0.446,0.446
					s0.2,0.446,0.446,0.446h39.794c0.246,0,0.446-0.2,0.446-0.446s-0.2-0.446-0.446-0.446H86.802z"/>
			</g>
			<path fill="#E6E6E6" d="M86.633,132.191h38.902v2.955c0,7.625-5.291,9.26-8.093,9.26H94.258c-4.203,0-7.625-3.969-7.625-9.959
				V132.191z"/>
		</g>
		<line fill="none" stroke="#F2F2F2" stroke-miterlimit="10" x1="165.895" y1="130.257" x2="344.07" y2="130.257"/>
		<line fill="none" stroke="#F2F2F2" stroke-miterlimit="10" x1="165.895" y1="138.524" x2="271.71" y2="138.524"/>
	</g>
</g>
</svg>

<!-- Generator: Adobe Illustrator 17.0.0, SVG Export Plug-In  -->
<svg version="1.1"
	 xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/"
	 x="0px" y="0px" width="30.793px" height="30.437px" viewBox="0 0 30.793 30.437" enable-background="new 0 0 30.793 30.437"
	 xml:space="preserve">
<defs>
</defs>
<g>
	<path fill="#999999" d="M24.857,30.437l-3.623-11.61l9.56-7.217H19.02L15.397,0l-3.623,11.61H0l9.56,7.217l-3.623,11.61l9.46-7.217
		L24.857,30.437z"/>
</g>
</svg>
<!-- Generator: Adobe Illustrator 17.0.0, SVG Export Plug-In  -->
<svg version="1.1"
	 xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/"
	 x="0px" y="0px" width="1440.03px" height="312.164px" viewBox="0 0 1440.03 312.164" enable-background="new 0 0 1440.03 312.164"
	 xml:space="preserve">
<defs>
</defs>
<g>
	<ellipse fill="#F2DDCF" cx="900.963" cy="175.154" rx="136" ry="55.09"/>
	<g>
		<g>
			<g>
				<path fill="#F1E9DE" stroke="#030104" stroke-width="10" d="M911.952,170.12c1.75,0.63,3.52,1.21,5.32,1.74
					c8.38,2.48,17.07,3.72,26.08,3.72c9,0,17.69-1.24,26.07-3.72c8.38-2.47,16.12-5.96,23.21-10.46c7.09-4.5,13.33-9.9,18.73-16.2
					c5.22-6.1,10.3-12.49,12.65-20.16c0.11-0.36,0.17-0.72,0.29-1.04c0.11-0.27,0.18-0.52,0.23-0.75c-0.05-0.21-0.12-0.46-0.23-0.73
					c-0.11-0.28-0.16-0.53-0.23-0.74c-2.52-7.67-7.46-14.2-12.71-20.38c-5.4-6.36-11.64-11.79-18.73-16.29
					c-7.09-4.5-14.83-7.99-23.21-10.46c-8.38-2.48-17.07-3.72-26.07-3.72c-9.01,0-17.7,1.24-26.08,3.72
					c-8.38,2.47-16.12,5.96-23.21,10.46c-7.09,4.5-13.33,9.93-18.73,16.29c-5.25,6.18-10.19,12.71-12.7,20.38
					c-0.07,0.21-0.13,0.46-0.23,0.74c-0.11,0.27-0.19,0.52-0.23,0.73c0.04,0.23,0.12,0.48,0.22,0.75c0.13,0.32,0.18,0.68,0.29,1.04
					c0.61,1.98,1.4,3.88,2.33,5.7"/>
			</g>
			<g>
				<path fill="#73582C" stroke="#030104" stroke-width="10" d="M974.381,143.373c-4.391,0-8.526-0.841-12.406-2.531
					c-3.885-1.685-7.26-3.966-10.126-6.835c-2.872-2.869-5.177-6.242-6.92-10.127c-1.746-3.879-2.616-8.07-2.616-12.574
					c0-4.385,0.87-8.521,2.616-12.405c1.743-3.879,4.048-7.255,6.92-10.127c2.866-2.866,6.242-5.174,10.126-6.92
					c3.879-1.743,8.014-2.616,12.406-2.616c4.385,0,8.52,0.873,12.405,2.616c3.879,1.746,7.255,4.053,10.126,6.92
					c2.867,2.872,5.174,6.248,6.92,10.127c1.743,3.884,2.616,8.02,2.616,12.405c0,4.504-0.873,8.695-2.616,12.574
					c-1.746,3.885-4.053,7.257-6.92,10.127c-2.872,2.869-6.247,5.15-10.126,6.835C982.901,142.532,978.766,143.373,974.381,143.373z
					"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#030104" stroke-width="10" d="M983.158,113.699c-1.129,0-2.222-0.253-3.292-0.76
					c-1.069-0.506-1.999-1.153-2.785-1.941c-0.789-0.786-1.434-1.687-1.941-2.7c-0.506-1.012-0.759-2.136-0.759-3.376
					c0-1.123,0.25-2.22,0.759-3.291c0.504-1.068,1.153-1.996,1.941-2.785c0.786-0.786,1.714-1.432,2.785-1.941
					c1.068-0.504,2.163-0.76,3.292-0.76c1.123,0,2.222,0.253,3.291,0.76c1.069,0.506,1.996,1.155,2.785,1.941
					c0.786,0.789,1.435,1.715,1.941,2.785c0.506,1.069,0.759,2.168,0.759,3.291c0,1.24-0.256,2.365-0.759,3.376
					c-0.509,1.015-1.155,1.915-1.941,2.7c-0.788,0.788-1.717,1.437-2.785,1.941C985.378,113.449,984.281,113.699,983.158,113.699z"
					/>
			</g>
		</g>
		<g>
			<polygon fill="#CF6E35" stroke="#030104" stroke-width="8" points="877.122,109.077 877.122,109.077 953.071,241.231
				801.846,241.231 			"/>
		</g>
		<g>
			<polygon fill="#DFA720" points="889.82,131.164 864.59,241.234 801.85,241.234 877.12,109.074 			"/>
		</g>
		<polygon fill="#C45C1B" points="889.82,131.164 864.59,241.234 801.85,241.234 877.12,109.074 		"/>
		<g>
			<polygon fill="none" stroke="#030104" stroke-width="8" points="877.122,109.077 877.122,109.077 953.071,241.231
				801.846,241.231 			"/>
		</g>
		<path fill="#030104" d="M887.754,215.914c-0.227-1.35-0.564-2.642-1.013-3.882c-0.454-1.234-1.182-2.305-2.194-3.207
			c-1.013-0.897-2.083-1.63-3.207-2.194c-1.128-0.559-2.479-0.844-4.05-0.844c-1.129,0-2.363,0.285-3.714,0.844
			c-1.35,0.564-2.421,1.297-3.206,2.194c-0.792,0.902-1.52,1.973-2.194,3.207c-0.676,1.24-0.902,2.532-0.676,3.882
			c-0.227,1.577,0,2.985,0.676,4.22c0.675,1.239,1.402,2.31,2.194,3.207c0.785,0.902,1.856,1.635,3.206,2.194
			c1.351,0.564,2.585,0.844,3.714,0.844c1.571,0,2.922-0.279,4.05-0.844c1.124-0.559,2.194-1.292,3.207-2.194
			c1.013-0.896,1.74-1.967,2.194-3.207C887.19,218.899,887.528,217.491,887.754,215.914z"/>
		<polygon fill="#030104" points="882.353,194.648 882.691,154.816 872.227,154.816 872.564,194.648 		"/>
	</g>
</g>
<g>
	<ellipse fill="#EEDBDA" cx="1278.11" cy="216.503" rx="136" ry="55.09"/>
	<g>
		<path fill="#ECE8E0" d="M1321.778,202.415v-49.884c19.759,0,40.973-14.11,40.973-35.653c0-11.517,0-21.662,0-54.033
			S1323.901,5,1301.082,5c-22.819,0-19.656,0-42.475,0s-61.67,25.473-61.67,57.845s0,42.517,0,54.033
			c0,21.543,21.214,35.653,40.973,35.653v49.884"/>
		<path fill="#D1D0CF" d="M1237.909,202.415c1.428-41.488-1.109-49.544-9.614-53.067c-5.83-2.415-14.464-2.701-26.546-10.164
			c-9.797-6.051-13.444-87.78,7.997-109.563c10.553-10.721,9.807-24.355,46.241-24.512c33.072-0.142,35.409-0.124,39.419,0.986
			c11.145,3.086,18.25,5.752,18.25,5.752"/>
		<path fill="#C6C4C0" stroke="#030104" stroke-width="10" stroke-linejoin="round" stroke-miterlimit="10" d="M1132.07,200.638
			c-0.14-0.92-0.21-1.86-0.21-2.82c0-10.88,8.82-19.69,19.69-19.69s19.69,8.81,19.69,19.69c0,7.16-3.82,13.43-9.54,16.87"/>
		<path fill="#C6C4C0" stroke="#030104" stroke-width="10" stroke-linejoin="round" stroke-miterlimit="10" d="M1434.82,283.244
			c0.14,0.92,0.21,1.86,0.21,2.82c0,10.88-8.82,19.69-19.69,19.69s-19.69-8.81-19.69-19.69c0-7.16,3.82-13.43,9.54-16.87"/>

			<circle fill="#ECE8E0" stroke="#030104" stroke-width="10" stroke-linejoin="round" stroke-miterlimit="10" cx="1151.55" cy="287.474" r="19.69"/>

			<circle fill="#ECE8E0" stroke="#030104" stroke-width="10" stroke-linejoin="round" stroke-miterlimit="10" cx="1415.34" cy="197.813" r="19.69"/>

			<line fill="none" stroke="#030104" stroke-width="10" stroke-linejoin="round" stroke-miterlimit="10" x1="1171.24" y1="205.445" x2="1395.65" y2="283.347"/>

			<line fill="none" stroke="#030104" stroke-width="10" stroke-linejoin="round" stroke-miterlimit="10" x1="1395.65" y1="205.445" x2="1171.24" y2="283.347"/>

			<line fill="none" stroke="#030104" stroke-width="10" stroke-linejoin="round" stroke-miterlimit="10" x1="1266.543" y1="149.348" x2="1266.543" y2="202.415"/>

			<line fill="none" stroke="#030104" stroke-width="10" stroke-linejoin="round" stroke-miterlimit="10" x1="1293.705" y1="149.348" x2="1293.705" y2="202.415"/>
		<path fill="none" stroke="#030104" stroke-width="10" stroke-linejoin="round" stroke-miterlimit="10" d="M1279.656,122.81
			c0,0-8.118,15.923-8.43,16.235c-0.312,0.312,16.235,0,16.235,0L1279.656,122.81z"/>

			<circle fill="none" stroke="#030104" stroke-width="10" stroke-linejoin="round" stroke-miterlimit="10" cx="1240.317" cy="108.136" r="24.352"/>

			<circle fill="none" stroke="#030104" stroke-width="10" stroke-linejoin="round" stroke-miterlimit="10" cx="1319.758" cy="108.136" r="24.352"/>
		<path fill="none" stroke="#030104" stroke-width="10" stroke-linejoin="round" stroke-miterlimit="10" d="M1233.183,33.615
			c0,25.092-15.89,35.579-31.434,35.579"/>
		<path fill="none" stroke="#030104" stroke-width="10" stroke-linejoin="round" stroke-miterlimit="10" d="M1325.763,33.615
			c0,25.092,15.89,35.579,31.434,35.579"/>
		<path fill="none" stroke="#030104" stroke-width="10" stroke-linejoin="round" stroke-miterlimit="10" d="M1321.778,202.415
			v-49.884c19.759,0,40.973-14.11,40.973-35.653c0-11.517,0-21.662,0-54.033S1323.901,5,1301.082,5c-22.819,0-19.656,0-42.475,0
			s-61.67,25.473-61.67,57.845s0,42.517,0,54.033c0,21.543,21.214,35.653,40.973,35.653v49.884"/>
	</g>
</g>
<g>
	<ellipse fill="#C5D9AD" cx="136" cy="196.758" rx="136" ry="55.09"/>
	<path fill="#030104" d="M199.369,119.389c1.351,2.252,2.532,4.726,3.544,7.426c1.013,2.701,1.856,5.401,2.532,8.102
		s1.182,5.459,1.519,8.27c0.337,2.816,0.506,5.686,0.506,8.608c0,4.277-0.396,8.497-1.182,12.658
		c-0.791,4.167-1.915,8.159-3.375,11.983c-1.466,3.829-3.207,7.542-5.232,11.139c-2.025,3.603-4.277,6.867-6.751,9.789
		l12.827,17.553l-8.101,6.076l-12.152-16.203c-3.154,2.927-6.53,5.517-10.127,7.764c-3.603,2.247-7.374,4.162-11.308,5.739
		c-3.94,1.572-8.101,2.753-12.49,3.544c-4.388,0.786-8.834,1.182-13.333,1.182c-4.504,0-8.945-0.396-13.333-1.182
		c-4.389-0.791-8.555-1.972-12.49-3.544c-3.94-1.577-7.711-3.492-11.308-5.739c-3.603-2.247-6.978-4.836-10.127-7.764
		l-12.152,16.203l-8.101-6.076l12.827-17.553c-2.479-2.922-4.726-6.187-6.751-9.789c-2.025-3.597-3.771-7.31-5.232-11.139
		c-1.466-3.824-2.59-7.817-3.375-11.983c-0.792-4.162-1.182-8.381-1.182-12.658c0-2.922,0.169-5.792,0.506-8.608
		c0.337-2.811,0.844-5.569,1.519-8.27s1.519-5.401,2.532-8.102c1.013-2.7,2.194-5.174,3.544-7.426
		c-1.351-1.351-2.532-2.753-3.544-4.22c-1.013-1.461-1.856-3.038-2.532-4.726c-0.675-1.688-1.182-3.428-1.519-5.232
		c-0.337-1.798-0.506-3.597-0.506-5.401c0-3.824,0.728-7.426,2.194-10.802c1.461-3.375,3.428-6.297,5.907-8.776
		c2.474-2.474,5.401-4.441,8.776-5.907c3.375-1.461,6.973-2.194,10.802-2.194c2.247,0,4.441,0.285,6.583,0.844
		c2.136,0.564,4.161,1.35,6.076,2.363c1.909,1.013,3.713,2.252,5.401,3.713c1.688,1.466,3.207,3.096,4.557,4.895
		c1.124-0.448,2.363-0.844,3.713-1.181c1.351-0.337,2.701-0.617,4.051-0.844c1.35-0.222,2.701-0.391,4.051-0.506
		c1.35-0.111,2.701-0.28,4.051-0.506V70.443h-10.127V60.317h30.38v10.127h-10.127v10.464c1.35,0.227,2.701,0.396,4.051,0.506
		c1.35,0.116,2.701,0.285,4.051,0.506c1.35,0.227,2.7,0.506,4.051,0.844c1.35,0.337,2.584,0.733,3.713,1.181
		c1.35-1.798,2.869-3.428,4.557-4.895c1.688-1.461,3.486-2.7,5.401-3.713c1.909-1.013,3.935-1.798,6.076-2.363
		c2.136-0.559,4.331-0.844,6.583-0.844c3.824,0,7.426,0.733,10.802,2.194c3.375,1.466,6.298,3.434,8.776,5.907
		c2.474,2.479,4.441,5.401,5.907,8.776c1.461,3.375,2.194,6.978,2.194,10.802c0,1.804-0.169,3.603-0.506,5.401
		c-0.337,1.804-0.844,3.544-1.519,5.232c-0.675,1.688-1.519,3.265-2.532,4.726C201.9,116.636,200.719,118.039,199.369,119.389z"/>
	<path fill="#030104" d="M75.148,99.811c0,0.902,0.11,1.856,0.337,2.869c0.222,1.013,0.448,1.915,0.675,2.701
		c0.221,0.791,0.559,1.635,1.012,2.532c0.449,0.902,0.897,1.688,1.351,2.363c1.798-2.474,3.713-4.778,5.738-6.92
		c2.025-2.136,4.162-4.162,6.414-6.076c2.247-1.909,4.61-3.655,7.089-5.232c2.474-1.572,5.063-3.038,7.764-4.388
		c-0.902-0.675-1.856-1.35-2.869-2.025s-2.025-1.292-3.038-1.856c-1.013-0.559-2.083-0.955-3.207-1.182
		c-1.128-0.221-2.363-0.337-3.713-0.337c-2.252,0-4.446,0.454-6.583,1.35c-2.141,0.902-4.05,2.194-5.738,3.882
		c-1.688,1.688-2.985,3.544-3.882,5.57C75.597,95.085,75.148,97.337,75.148,99.811z"/>
	<path fill="#E3EDDA" d="M136.246,212.892c8.323,0,16.203-1.63,23.629-4.895c7.426-3.26,13.892-7.648,19.409-13.165
		c5.512-5.512,9.9-11.925,13.165-19.241c3.26-7.31,4.895-15.243,4.895-23.797c0-8.323-1.635-16.203-4.895-23.629
		c-3.265-7.426-7.653-13.893-13.165-19.41c-5.517-5.512-11.983-9.9-19.409-13.165c-7.426-3.26-15.306-4.895-23.629-4.895
		c-8.329,0-16.203,1.635-23.629,4.895c-7.426,3.265-13.897,7.653-19.409,13.165c-5.517,5.517-9.905,11.983-13.165,19.41
		c-3.265,7.426-4.895,15.306-4.895,23.629c0,8.555,1.629,16.487,4.895,23.797c3.259,7.315,7.647,13.729,13.165,19.241
		c5.512,5.517,11.983,9.905,19.409,13.165C120.043,211.262,127.917,212.892,136.246,212.892z"/>
	<path fill="#030104" d="M179.791,82.258c-1.35,0-2.59,0.116-3.713,0.337c-1.129,0.227-2.194,0.623-3.207,1.182
		c-1.013,0.564-2.025,1.181-3.038,1.856s-1.973,1.466-2.869,2.363c2.701,1.129,5.285,2.479,7.764,4.051
		c2.474,1.577,4.837,3.323,7.089,5.232c2.247,1.915,4.388,3.94,6.414,6.076c2.025,2.142,3.935,4.446,5.738,6.92
		c0.448-0.675,0.897-1.461,1.351-2.363c0.448-0.896,0.786-1.741,1.012-2.532c0.222-0.786,0.449-1.688,0.675-2.701
		c0.222-1.013,0.337-1.967,0.337-2.869c0-2.474-0.453-4.726-1.35-6.751c-0.902-2.025-2.194-3.882-3.882-5.57
		c-1.688-1.688-3.603-2.98-5.738-3.882C184.231,82.712,182.038,82.258,179.791,82.258z"/>
	<path fill="#CBDDC0" d="M159.071,95.248l-52.25,109.86c0,0-9.59-6.25-13.61-10.28c-5.52-5.51-9.91-11.92-13.17-19.24
		c-3.26-7.31-4.89-15.24-4.89-23.79c0-8.33,1.63-16.21,4.89-23.63c3.26-7.43,7.65-13.89,13.17-19.41
		c5.51-5.51,11.98-9.9,19.41-13.17c7.42-3.26,15.3-4.89,23.63-4.89C144.27,90.698,151.89,92.218,159.071,95.248z"/>
	<polygon fill="#030104" points="141.309,151.795 141.309,121.414 131.183,121.414 131.183,161.921 182.153,161.921
		182.153,151.795 	"/>
</g>
<g>
	<ellipse fill="#F3E1BF" cx="518.482" cy="195.869" rx="136" ry="55.09"/>
	<g>
		<polygon fill="#E7B730" stroke="#030104" stroke-width="8" points="518.264,61.208 518.264,61.208 594.214,193.362
			442.989,193.362 		"/>
	</g>
	<g>
		<polygon fill="#DFA720" points="530.962,83.295 505.732,193.365 442.992,193.365 518.262,61.205 		"/>
	</g>
	<polygon fill="#D49B1D" points="530.962,83.295 505.732,193.365 442.992,193.365 518.262,61.205 	"/>
	<g>
		<polygon fill="none" stroke="#030104" stroke-width="8" points="518.264,61.208 518.264,61.208 594.214,193.362 442.989,193.362
					"/>
	</g>
	<path fill="#030104" d="M528.897,168.045c-0.227-1.35-0.564-2.642-1.013-3.882c-0.454-1.234-1.182-2.305-2.194-3.207
		c-1.013-0.897-2.083-1.63-3.207-2.194c-1.128-0.559-2.479-0.844-4.05-0.844c-1.129,0-2.363,0.285-3.714,0.844
		c-1.35,0.564-2.421,1.297-3.206,2.194c-0.792,0.902-1.52,1.973-2.194,3.207c-0.676,1.24-0.902,2.532-0.676,3.882
		c-0.227,1.577,0,2.985,0.676,4.22c0.675,1.239,1.402,2.31,2.194,3.207c0.785,0.902,1.856,1.635,3.206,2.194
		c1.351,0.564,2.585,0.844,3.714,0.844c1.571,0,2.922-0.279,4.05-0.844c1.124-0.559,2.194-1.292,3.207-2.194
		c1.013-0.896,1.74-1.967,2.194-3.207C528.333,171.03,528.67,169.622,528.897,168.045z"/>
	<polygon fill="#030104" points="523.496,146.779 523.833,106.947 513.37,106.947 513.707,146.779 	"/>
</g>
</svg>
