import { Component, OnInit, Input } from '@angular/core';
import { HoteldataService } from 'src/app/services/hoteldata.service';
import { Hotels } from 'src/app/planning/planning';
import { data_hotel } from 'src/app/services/current-config';

@Component({
  selector: 'app-subheader',
  templateUrl: './subheader.component.html'
})
export class SubheaderComponent implements OnInit {

  @Input ("title") gn_title : string = "Texto simulado";

  constructor(
    private _hoteldataService : HoteldataService,
  ) { }

  SelectedHotel:data_hotel = this._hoteldataService.currentConfig.data.hotel;

  ngOnInit() {
    this._hoteldataService.restore();
    if (this._hoteldataService.currentConfig.data.hotel.clave!=="") this.SelectedHotel = this._hoteldataService.currentConfig.data.hotel;
  }

}
