<main class="sortable">

    <app-subheader title="Secciones cuestionario"></app-subheader>

  <section class="sub-wrapper">
    <div class="sort-tools row">
      <small></small>
      <button class="button-app column" (click)="selectAllSections()">
        <div class="row">
          <i class="icon icon-plus"></i>  &nbsp;Seleccionar
        </div>
        <span class="row">
          <small> todas las secciones</small>
        </span>
      </button>

      <button class="button-app" (click)="removeAllSections()">
        <div class="row">
          <i class="icon icon-close"></i> &nbsp;Deseleccionar
        </div>
        <span class="row">
          <small> todas las secciones</small>
        </span>
      </button>
    </div>

    <section class="columnas-tabs opcionales">
      <div class="secciones">
        <ng-container *ngFor="let category of categorias">
          <div class="ind-section" [ngClass]="{ pickedSection: category.selected }" (click)="toggleCagetory(category)">
            <span>{{ category.name }}</span>
            <i class="icon icon-circle" *ngIf="!category.selected"></i>
            <i class="icon icon-close" *ngIf="category.selected"></i>
          </div>
        </ng-container>
      </div>
    </section>

    <div class="action">
      <button class="button-app" (click)="back()">Volver</button>
      <button class="button-app" [disabled]="this.ActiveSections.length==0" (click)="send()">Organizar recorrido</button>
    </div>
  </section>
</main>
