import { Component, OnInit } from '@angular/core';
import { AuthService } from '../rest/auth/auth.service';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html'
})
export class StartComponent implements OnInit{
  title:string="start";
  log_btn:boolean=true;

  constructor(private _authService:AuthService) {
    this._authService.sessionStatus
    .subscribe( este => {
      this.log_btn = this._authService.logged;
    });
  }

  logout(){
    this._authService.logout();
  }

  ngOnInit(){
    this._authService.sessionStatus.next();
  }
}
