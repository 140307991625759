import { Component, OnInit } from '@angular/core';
import { HoteldataService } from 'src/app/services/hoteldata.service';
import { Sections } from '../planning';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Router } from '@angular/router';
import { EvalService } from 'src/app/rest/eval.service';
import { last, mergeMap, pluck } from 'rxjs/operators';

@Component({
  selector: 'app-rearrange',
  templateUrl: './rearrange.component.html'
})
export class RearrangeComponent implements OnInit {
  title:string = "Ordenar secciones";
  state = "";
  position = "";

  constructor(
    private _hoteldataService : HoteldataService,
    private _router : Router,
    private _evalService : EvalService
  ) { }

  secciones$ = this._hoteldataService.secciones$;
  generatedArray:Array<Sections> = [];
  stagedArray:Array<string> = [];

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.generatedArray, event.previousIndex, event.currentIndex);
    this._hoteldataService.currentConfig.data.test.saved=false;
  }

  back(){
    this.setChangesToSend();
    this._router.navigate(['/planning/sort']);
  }

  remove(este){
    console.log("Remove?",este);
    let index = this.generatedArray.indexOf(este);
    this.generatedArray.splice(index,1);


    let indexConfig = this._hoteldataService.currentConfig.data.secciones.indexOf(este.id);
    this._hoteldataService.currentConfig.data.secciones.splice(indexConfig,1);
    this._hoteldataService.currentConfig.data.test.saved=false;
  }

  async prepareVirtualarray(){
    const secciones = await this.secciones$.toPromise();

    this._hoteldataService.currentConfig.data.secciones.forEach(clave => {
      let object = secciones.find( el => el.id == clave );
      this.generatedArray.push(object);
    });
  }

  setChangesToSend(){
    this._hoteldataService.currentConfig.data.secciones=[];
    this.generatedArray.forEach(obj=>{
      let sec:Sections = obj;
      this.stagedArray.push(sec.id);
    });
    this._hoteldataService.currentConfig.secciones = this.stagedArray;
    //console.log("this._hoteldataService.currentConfig.data.secciones",this._hoteldataService.currentConfig.data.secciones);
    this._hoteldataService.currentConfig.data.test.saved=false;
    // this._hoteldataService.changing();
  }

  updateChanges(){
    this._hoteldataService.restore();

    const clave = this._hoteldataService.currentConfig.data.hotel.clave;
    let test_id:any = Number(this._hoteldataService.currentConfig.data.test.id);
    // let _syncData = this._evalService.syncData;

    let sending = new Promise( (resolve,reject) => {
      if(!test_id||test_id==null||test_id==""){

        this._evalService.evalIndex(clave).pipe(
          last(),
          mergeMap((ids: number[])=>this._evalService.evalDetail(clave,ids[0])),
          pluck("id")
        ).subscribe(
          (res:number)=>{
            test_id = res;
            this._hoteldataService.currentConfig.data.test.id = res;
            // this._hoteldataService.changing();
            resolve(res);
          }
          );
        }else resolve(test_id);

      });

      sending.then((val:number)=>{
        // _syncData.id = val;
        // _syncData.cuestionario_clave = this._hoteldataService.currentConfig.data.test.clave;
        let _syncData = {
          "secciones" : JSON.stringify(this.stagedArray)
        }

        this._evalService.updateEval(_syncData,val)
        .subscribe(
          res=>{
            console.log("res",Object.keys(res)[0]);
            this._hoteldataService.currentConfig.data.test.saved=true;
            this._hoteldataService.forceUpload.next(this._hoteldataService.currentConfig.data);
            // this._hoteldataService.changing();
            this._router.navigate(['/test']);
          },err=>{
            console.log("err",err);
          }
        )
      })
  }

  sendOrderedArray(){
    this.setChangesToSend();
    // this._testdataService.strdData();
    this.updateChanges()
  }

  async ngOnInit() {
    this._hoteldataService.restore();
    await this.prepareVirtualarray();
  }

}
