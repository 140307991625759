import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { AtomicComponent } from './atomic/atomic.component';
import { NewuserComponent } from './login/newuser/newuser.component';
import { HotelsearchComponent } from './planning/hotelsearch/hotelsearch.component';
import { StartComponent } from './start/start.component';
import { SortComponent } from './planning/sort/sort.component';
import { RearrangeComponent } from './planning/rearrange/rearrange.component';
import { TestComponent } from './test/test.component';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    component: StartComponent ,
    children: [
      { path: '', component: LoginComponent },
      { path: 'acceso', component: NewuserComponent },
      { path: 'planning', component: HotelsearchComponent, canActivate: [AuthGuard] },
      { path: 'planning/arrange', component: RearrangeComponent, canActivate: [AuthGuard]}
    ]
  },
  { path: 'planning/sort', component: SortComponent, canActivate: [AuthGuard] },
  { path: 'test', component: TestComponent, canActivate: [AuthGuard] },
  { path: 'atomic', component: AtomicComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
  ],
  exports: [ RouterModule ]
})
export class AppRoutingModule { }
