import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

export interface quickHotelSearch {
  id:number,
  clave:string,
  hotel:string,
}

export interface hotelDetails {
  id?: number,
  clave?:string,
  hotel?:string,
  status?:string,
  description?:string,
  habitaciones?:number,
  url?:string,
  ubicacion?:string,
  telefonos?:string,
  logo?:string,
  gerente_general?:string,
  gerente_mantto?:string,
  url_google_maps?:string,
  url_google_photos?:string,
  url_tripadvisor?:string,
  operacion?:string,
  zona?:string,
  operadora_id?: number,
  marca_id?: number,
  created_at?: any,
  updated_at?: any,
  latitud?:string,
  longitud?:string,
  segmento?:string,
  estado?:string,
  region?:string,
  group_fiho?:string,
}

@Injectable({
  providedIn: 'root'
})
export class HotelsService {

  constructor( public _httpClient: HttpClient ) { }

  hotelList(){
    return this._httpClient.get(environment.quickSearch);
  }

  hotelDetails(clave:string){
    return this._httpClient.get(`${environment.Protocol}://${environment.Host}/api/hoteles/${clave}/show`);
  }

  userDetails(id:number){
    return this._httpClient.get(`${environment.user}/${id}`)
  }

}
